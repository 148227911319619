import { Helmet } from 'react-helmet';
import ScrollToTop from '../../assets/hooks/scrollToTop';
import Numbers from '../Numbers/Numbers';
import HowBanner from './HowBanner/HowBanner';
import HowCards from './HowCards/HowCards';
import './HowItWork.css';

function HowItWork({
    onStartPopupOpen,
}) {
    return (
        <div className='how-it-work'>
            <Helmet>
                <title>Polza - Как это работает?</title>
            </Helmet>
            <div className='how-it-work__box'>
                <ScrollToTop />
                <HowBanner />
                <HowCards onStartPopupOpen={onStartPopupOpen} />
            </div>
            <svg
                width={1340}
                height={774}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className='how-banner__sq-1'
                viewBox='0 0 1340 774'
            >
                <path
                    opacity={0.5}
                    d="M670 0l670 387-670 387L0 387 670 0z"
                    fill="#332493"
                />
            </svg>
            <svg
                width={848}
                height={490}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className='how-banner__sq-2'
                viewBox='0 0 848 490'
            >
                <path
                    opacity={0.5}
                    d="M424 0l424 245-424 245L0 245 424 0z"
                    fill="#080E3C"
                />
            </svg>

            <svg
                width={442}
                height={198}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className='how-banner__sq-3'
                viewBox='0 0 442 198'
            >
                <path
                    d="M170.985 0l170.986 98.797-170.986 98.797L0 98.797 170.985 0z"
                    fill="#1A2267"
                />
                <path
                    d="M362.174 102.986L442 149.058l-79.826 46.073-79.826-46.073 79.826-46.072z"
                    fill="#262E77"
                />
            </svg>
        </div>
    );
}

export default HowItWork