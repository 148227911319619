import { PlankCloseIcon, PlankRhomb } from "../../../assets/icon/mainIcon";
import "./PlankContainer.css";

function PlankContainer({ type, isOpen = true, onClose, children }) {
  return (
    <div
      className={`plank plank_type_${type} ${isOpen ? "plank_visible" : ""}`}
    >
      <div className="plank__container">
        {type === "business" ? (
          <button
            className="plank__close-btn"
            type="button"
            aria-label="Закрыть"
            onClick={onClose}
          >
            <PlankCloseIcon
              mainClassName="plank__close-icon"
              fillClassName="plank__close-icon-fill"
              bgClassName="plank__close-icon-bg"
            />
          </button>
        ) : null}
        <div className="plank__content">
          <PlankRhomb
            mainClassName="plank__top-left-rhomb"
            fillClassName="plank__top-left-rhomb-fill"
          />
          <PlankRhomb
            mainClassName="plank__bottom-left-rhomb"
            fillClassName="plank__bottom-left-rhomb-fill"
          />
          <PlankRhomb
            mainClassName="plank__bottom-right-rhomb"
            fillClassName="plank__bottom-right-rhomb-fill"
          />
          {children}
        </div>
      </div>
    </div>
  );
}

export default PlankContainer;
