import screen from '../../../assets/img/Portfolio/alclic/Screen-1.webp';
import screen2 from '../../../assets/img/Portfolio/alclic/Screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/alclic/Screen-3.webp';

import phone1 from '../../../assets/img/Portfolio/alclic/Phone-1.webp';
import phone2 from '../../../assets/img/Portfolio/alclic/Phone-2.webp';
import phone3 from '../../../assets/img/Portfolio/alclic/Phone-3.webp';
import viz1 from '../../../assets/img/Portfolio/alclic/Viz-1.webp';
import viz2 from '../../../assets/img/Portfolio/alclic/Viz-2.webp';
import bottle from '../../../assets/img/Portfolio/alclic/Bottle.webp';
import logomaking from '../../../assets/img/Portfolio/alclic/logo-making-2.webp';
import sparkle from '../../../assets/img/Portfolio/alclic/sparkle.png';

import './Alclic.css';
import { motion } from "framer-motion";

const Alclic = () => {

  return (
    <>
      <img className='screen' alt='' src={screen}></img>
      <img className='screen' alt='' src={screen2}></img>
      <img className='screen' alt='' src={screen3}></img>
      <div
        className='phones__block'>
        <motion.img
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.2,
          }} className='phone' alt='' src={phone1}></motion.img>
        <motion.img
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.3,
          }} className='phone' alt='' src={phone2}></motion.img>
        <motion.img
          initial={{ opacity: 0, scale: 0 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.4,
          }} className='phone' alt='' src={phone3}></motion.img>
        <div className='second-bg'></div>
      </div>
      <div className='making-logo'>
        <img className='making-logo__first-part' src={logomaking} alt=''></img>

        <motion.img initial={{ opacity: 0.5, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, repeat: Infinity }} className="animate-icon__sparkle" src={sparkle} alt=''>
        </motion.img>

      </div>
      <div className='viz__block'>
        <img className='viz' alt='' src={viz1}></img>
        <img className='viz' alt='' src={viz2}></img>
      </div>
      <img className='bottle' alt='' src={bottle}></img>

    </>

  )
}
export default Alclic