export const SuiteData = [
    {
        img: <svg xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102" fill="none">
        <path d="M7.29019 43.379V29.465C7.29019 27.6435 8.19214 26.0244 9.75418 25.1643L21.797 18.3843" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M65.5884 91.6989L53.3441 98.4788V98.4282C51.8325 99.2377 50.0689 99.2377 48.6076 98.3776L36.3633 91.5471" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M94.7141 58.7094V72.5728V72.5222C94.7141 74.2425 93.7568 75.9122 92.1947 76.7723L80.1016 83.5017" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36.4141 10.3899L48.608 3.55937H48.5576C50.0189 2.69923 51.7825 2.69923 53.2437 3.55937L65.4377 10.3393" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.0273 3.00249V16.9165" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.0273 85.1714V99.0855" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.94531 27.0865L19.9881 33.7147" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M81.9648 68.3732L94.0076 75.0013" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M94.7141 43.379V29.5156V29.465C94.7141 27.6941 93.7568 26.0244 92.1947 25.1643L80.1016 18.3843" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.29019 58.7094V72.5728L7.28516 72.5222C7.28516 74.2425 8.19214 75.9122 9.75418 76.7723L21.797 83.5017" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M94.058 27.0865L81.9648 33.7147" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M48.7653 70.7013L35.0597 62.8082C33.5984 61.948 32.7418 60.4302 32.7418 58.8111V43.1767L32.6915 43.1261C32.6411 41.4564 33.548 39.9385 34.9589 39.129L48.6141 31.2359H48.5637C49.9242 30.4264 51.6374 30.4264 53.0483 31.1853L66.7035 39.0278C68.1144 39.8374 68.971 41.3552 68.971 42.9743V58.5581C68.971 60.1772 68.064 61.6951 66.6531 62.5046L52.9475 70.3471V70.2965C51.5366 71.0554 49.8234 71.0554 48.4126 70.2965L48.7653 70.7013Z" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.3906 40.9502L51.0265 51.0695" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M68.6632 40.9502L51.0273 51.0695" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.0273 51.0695V71.3081" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.0385 68.3732L7.94531 75.0013" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,

        imgTablet: <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 102 102" fill="none">
        <path d="M7.29019 43.379V29.465C7.29019 27.6435 8.19214 26.0244 9.75418 25.1643L21.797 18.3843" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M65.5884 91.6989L53.3441 98.4788V98.4282C51.8325 99.2377 50.0689 99.2377 48.6076 98.3776L36.3633 91.5471" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M94.7141 58.7094V72.5728V72.5222C94.7141 74.2425 93.7568 75.9122 92.1947 76.7723L80.1016 83.5017" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36.4141 10.3899L48.608 3.55937H48.5576C50.0189 2.69923 51.7825 2.69923 53.2437 3.55937L65.4377 10.3393" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.0273 3.00249V16.9165" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.0273 85.1714V99.0855" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.94531 27.0865L19.9881 33.7147" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M81.9648 68.3732L94.0076 75.0013" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M94.7141 43.379V29.5156V29.465C94.7141 27.6941 93.7568 26.0244 92.1947 25.1643L80.1016 18.3843" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.29019 58.7094V72.5728L7.28516 72.5222C7.28516 74.2425 8.19214 75.9122 9.75418 76.7723L21.797 83.5017" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M94.058 27.0865L81.9648 33.7147" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M48.7653 70.7013L35.0597 62.8082C33.5984 61.948 32.7418 60.4302 32.7418 58.8111V43.1767L32.6915 43.1261C32.6411 41.4564 33.548 39.9385 34.9589 39.129L48.6141 31.2359H48.5637C49.9242 30.4264 51.6374 30.4264 53.0483 31.1853L66.7035 39.0278C68.1144 39.8374 68.971 41.3552 68.971 42.9743V58.5581C68.971 60.1772 68.064 61.6951 66.6531 62.5046L52.9475 70.3471V70.2965C51.5366 71.0554 49.8234 71.0554 48.4126 70.2965L48.7653 70.7013Z" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.3906 40.9502L51.0265 51.0695" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M68.6632 40.9502L51.0273 51.0695" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.0273 51.0695V71.3081" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.0385 68.3732L7.94531 75.0013" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,

        id: 0,

        num: '01',

        txt: 'Гибкость в&nbsp;управлении сайтом и&nbsp;оперативность в&nbsp;обновлении контента.',
    },
    {
        img: <svg xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102" fill="none">
        <path d="M7.28516 36.4286H94.7137" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M46.1434 21.8329L46.1191 21.8571L46.1434 21.8814L46.1677 21.8571L46.1434 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.9989 21.8329L33.9746 21.8571L33.9989 21.8814L34.0232 21.8571L33.9989 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.8563 21.8329L21.832 21.8571L21.8563 21.8814L21.8806 21.8571L21.8563 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M46.1434 21.8329L46.1191 21.8571L46.1434 21.8814L46.1677 21.8571L46.1434 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.9989 21.8329L33.9746 21.8571L33.9989 21.8814L34.0232 21.8571L33.9989 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.8563 21.8329L21.832 21.8571L21.8563 21.8814L21.8806 21.8571L21.8563 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M80.1423 7.28572H21.8566C13.809 7.28572 7.28516 13.8096 7.28516 21.8571V80.1429C7.28516 88.1904 13.809 94.7143 21.8566 94.7143H80.1423C88.1899 94.7143 94.7137 88.1904 94.7137 80.1429V21.8571C94.7137 13.8096 88.1899 7.28572 80.1423 7.28572Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M65.5703 78.9286L59.5996 72.9579" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36.4277 63.1428C36.4277 65.7846 37.2111 68.367 38.6788 70.5636C40.1465 72.7602 42.2326 74.4722 44.6733 75.4832C47.114 76.4941 49.7997 76.7587 52.3907 76.2433C54.9818 75.7279 57.3618 74.4557 59.2298 72.5877C61.0978 70.7197 62.37 68.3397 62.8854 65.7486C63.4008 63.1576 63.1362 60.4719 62.1253 58.0312C61.1143 55.5905 59.4023 53.5044 57.2057 52.0367C55.0091 50.569 52.4267 49.7856 49.7849 49.7856C46.2424 49.7859 42.8451 51.1933 40.3402 53.6981C37.8353 56.203 36.428 59.6003 36.4277 63.1428Z" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,

        imgTablet: <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 102 102" fill="none">
        <path d="M7.28516 36.4286H94.7137" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M46.1434 21.8329L46.1191 21.8571L46.1434 21.8814L46.1677 21.8571L46.1434 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.9989 21.8329L33.9746 21.8571L33.9989 21.8814L34.0232 21.8571L33.9989 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.8563 21.8329L21.832 21.8571L21.8563 21.8814L21.8806 21.8571L21.8563 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M46.1434 21.8329L46.1191 21.8571L46.1434 21.8814L46.1677 21.8571L46.1434 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.9989 21.8329L33.9746 21.8571L33.9989 21.8814L34.0232 21.8571L33.9989 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.8563 21.8329L21.832 21.8571L21.8563 21.8814L21.8806 21.8571L21.8563 21.8329Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M80.1423 7.28572H21.8566C13.809 7.28572 7.28516 13.8096 7.28516 21.8571V80.1429C7.28516 88.1904 13.809 94.7143 21.8566 94.7143H80.1423C88.1899 94.7143 94.7137 88.1904 94.7137 80.1429V21.8571C94.7137 13.8096 88.1899 7.28572 80.1423 7.28572Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M65.5703 78.9286L59.5996 72.9579" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36.4277 63.1428C36.4277 65.7846 37.2111 68.367 38.6788 70.5636C40.1465 72.7602 42.2326 74.4722 44.6733 75.4832C47.114 76.4941 49.7997 76.7587 52.3907 76.2433C54.9818 75.7279 57.3618 74.4557 59.2298 72.5877C61.0978 70.7197 62.37 68.3397 62.8854 65.7486C63.4008 63.1576 63.1362 60.4719 62.1253 58.0312C61.1143 55.5905 59.4023 53.5044 57.2057 52.0367C55.0091 50.569 52.4267 49.7856 49.7849 49.7856C46.2424 49.7859 42.8451 51.1933 40.3402 53.6981C37.8353 56.203 36.428 59.6003 36.4277 63.1428Z" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,

        id: 1,

        num: '02',

        txt: 'Легкая оптимизация сайта под поисковые системы и  интеграция в маркетинговые каналы.',
    },
    {
        img: <svg xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102" fill="none">
        <path d="M23.348 36.8333H12.5273" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.348 65.1667H12.5273" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.347 50.9999H5.3125" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.7988 20.9479C28.852 15.0042 36.5643 10.9565 44.9603 9.3166C53.3563 7.67673 62.059 8.51837 69.9679 11.7351C77.8768 14.9518 84.6366 20.3991 89.3926 27.3882C94.1486 34.3773 96.6871 42.5943 96.6871 51C96.6871 59.4057 94.1486 67.6226 89.3926 74.6117C84.6366 81.6008 77.8768 87.0481 69.9679 90.2648C62.059 93.4815 53.3563 94.3232 44.9603 92.6833C36.5643 91.0434 28.852 86.9957 22.7988 81.052" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41.76 38.6368L46.154 49.209C46.629 50.3518 46.6292 51.6318 46.1543 52.7747L41.7547 63.3644C41.3798 64.2668 41.2984 65.2611 41.5218 66.2108C41.7452 67.1605 42.2623 68.019 43.0024 68.6686C43.7424 69.3183 44.669 69.7271 45.655 69.8391C46.6411 69.9511 47.6383 69.7607 48.5098 69.2942L74.9434 55.1451C75.7021 54.739 76.3353 54.1396 76.7765 53.41C77.2177 52.6804 77.4506 51.8475 77.4506 50.999C77.4506 50.1505 77.2177 49.3176 76.7765 48.588C76.3353 47.8584 75.7021 47.2591 74.9434 46.8529L48.5148 32.7064C47.6432 32.2398 46.646 32.0495 45.6599 32.1615C44.6738 32.2736 43.7471 32.6825 43.0071 33.3322C42.2671 33.9819 41.75 34.8405 41.5267 35.7903C41.3034 36.74 41.3849 37.7343 41.76 38.6368Z" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M74.926 50.9999H48.5957" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,
        imgTablet:
        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 102 102" fill="none">
        <path d="M23.348 36.8333H12.5273" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.348 65.1667H12.5273" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.347 50.9999H5.3125" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.7988 20.9479C28.852 15.0042 36.5643 10.9565 44.9603 9.3166C53.3563 7.67673 62.059 8.51837 69.9679 11.7351C77.8768 14.9518 84.6366 20.3991 89.3926 27.3882C94.1486 34.3773 96.6871 42.5943 96.6871 51C96.6871 59.4057 94.1486 67.6226 89.3926 74.6117C84.6366 81.6008 77.8768 87.0481 69.9679 90.2648C62.059 93.4815 53.3563 94.3232 44.9603 92.6833C36.5643 91.0434 28.852 86.9957 22.7988 81.052" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M41.76 38.6368L46.154 49.209C46.629 50.3518 46.6292 51.6318 46.1543 52.7747L41.7547 63.3644C41.3798 64.2668 41.2984 65.2611 41.5218 66.2108C41.7452 67.1605 42.2623 68.019 43.0024 68.6686C43.7424 69.3183 44.669 69.7271 45.655 69.8391C46.6411 69.9511 47.6383 69.7607 48.5098 69.2942L74.9434 55.1451C75.7021 54.739 76.3353 54.1396 76.7765 53.41C77.2177 52.6804 77.4506 51.8475 77.4506 50.999C77.4506 50.1505 77.2177 49.3176 76.7765 48.588C76.3353 47.8584 75.7021 47.2591 74.9434 46.8529L48.5148 32.7064C47.6432 32.2398 46.646 32.0495 45.6599 32.1615C44.6738 32.2736 43.7471 32.6825 43.0071 33.3322C42.2671 33.9819 41.75 34.8405 41.5267 35.7903C41.3034 36.74 41.3849 37.7343 41.76 38.6368Z" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M74.926 50.9999H48.5957" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,

        id: 2,

        num: '03',

        txt: 'Рассылки информации по заполненным формам обратной связи в популярные мессенджеры.',
    },
    {
        img: <svg xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102" fill="none">
        <path d="M60.7129 16.5331V28.5949C60.7129 35.7973 70.8052 41.1466 75.4462 43.2263C76.1586 43.5479 76.9313 43.7143 77.7129 43.7143C78.4945 43.7143 79.2672 43.5479 79.9796 43.2263C84.6206 41.1466 94.7129 35.8086 94.7129 28.5949V16.5331C94.712 15.3679 94.3518 14.2312 93.6816 13.278C93.0113 12.3248 92.0635 11.6015 90.9672 11.2064L82.3142 8.0898C79.3364 7.01769 76.078 7.01769 73.1002 8.0898L64.4586 11.2008C63.3614 11.5961 62.4129 12.3204 61.7426 13.2747C61.0722 14.229 60.7126 15.3669 60.7129 16.5331Z" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M40.7402 82.5714H61.2587C62.0583 82.5714 62.8456 82.3739 63.5506 81.9966C64.2556 81.6193 64.8566 81.0737 65.3001 80.4084L67.2732 77.4486C67.7167 76.7833 68.3177 76.2377 69.0227 75.8604C69.7277 75.4831 70.515 75.2856 71.3146 75.2856H89.8566C91.1448 75.2856 92.3802 75.7974 93.2911 76.7083C94.202 77.6192 94.7137 78.8546 94.7137 80.1428V84.9999C94.7137 87.5763 93.6903 90.0472 91.8685 91.869C90.0467 93.6907 87.5758 94.7142 84.9994 94.7142H16.9994C14.4231 94.7142 11.9522 93.6907 10.1304 91.869C8.30862 90.0472 7.28516 87.5763 7.28516 84.9999V80.4284C7.28516 79.0644 7.82698 77.7564 8.79143 76.7919C9.75588 75.8275 11.064 75.2856 12.4279 75.2856H30.6842C31.4839 75.2856 32.2712 75.4831 32.9762 75.8604C33.6812 76.2377 34.2822 76.7833 34.7257 77.4486L36.6988 80.4084C37.1423 81.0737 37.7432 81.6193 38.4483 81.9966C39.1533 82.3739 39.9405 82.5714 40.7402 82.5714Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M89.8556 75.2857L89.8555 53.5715" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.1426 75.2857V22.7143C12.1418 19.9871 13.2245 17.3713 15.1524 15.4423C17.0802 13.5134 19.6954 12.4293 22.4226 12.4285H48.1426" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,

        imgTablet: <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 102 102" fill="none">
        <path d="M60.7129 16.5331V28.5949C60.7129 35.7973 70.8052 41.1466 75.4462 43.2263C76.1586 43.5479 76.9313 43.7143 77.7129 43.7143C78.4945 43.7143 79.2672 43.5479 79.9796 43.2263C84.6206 41.1466 94.7129 35.8086 94.7129 28.5949V16.5331C94.712 15.3679 94.3518 14.2312 93.6816 13.278C93.0113 12.3248 92.0635 11.6015 90.9672 11.2064L82.3142 8.0898C79.3364 7.01769 76.078 7.01769 73.1002 8.0898L64.4586 11.2008C63.3614 11.5961 62.4129 12.3204 61.7426 13.2747C61.0722 14.229 60.7126 15.3669 60.7129 16.5331Z" stroke="#07F0FF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M40.7402 82.5714H61.2587C62.0583 82.5714 62.8456 82.3739 63.5506 81.9966C64.2556 81.6193 64.8566 81.0737 65.3001 80.4084L67.2732 77.4486C67.7167 76.7833 68.3177 76.2377 69.0227 75.8604C69.7277 75.4831 70.515 75.2856 71.3146 75.2856H89.8566C91.1448 75.2856 92.3802 75.7974 93.2911 76.7083C94.202 77.6192 94.7137 78.8546 94.7137 80.1428V84.9999C94.7137 87.5763 93.6903 90.0472 91.8685 91.869C90.0467 93.6907 87.5758 94.7142 84.9994 94.7142H16.9994C14.4231 94.7142 11.9522 93.6907 10.1304 91.869C8.30862 90.0472 7.28516 87.5763 7.28516 84.9999V80.4284C7.28516 79.0644 7.82698 77.7564 8.79143 76.7919C9.75588 75.8275 11.064 75.2856 12.4279 75.2856H30.6842C31.4839 75.2856 32.2712 75.4831 32.9762 75.8604C33.6812 76.2377 34.2822 76.7833 34.7257 77.4486L36.6988 80.4084C37.1423 81.0737 37.7432 81.6193 38.4483 81.9966C39.1533 82.3739 39.9405 82.5714 40.7402 82.5714Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M89.8556 75.2857L89.8555 53.5715" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.1426 75.2857V22.7143C12.1418 19.9871 13.2245 17.3713 15.1524 15.4423C17.0802 13.5134 19.6954 12.4293 22.4226 12.4285H48.1426" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>,

        id: 3,

        num: '04',

        txt: 'Возможность передачи полного или частичного управления онлайн-магазином нашим менеджерам.',
    },
]