
import './ServiceTemplate.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import { sendMetriс } from '../../../assets/utils/utils';
import imageLaptop from '../../../assets/images/MainMarketingLaptop.png';
import imageMobile from '../../../assets/images/MainMarketingMobile.png';
import ServiceNumberSide from './ServiceNumberSide/ServiceNumberSide';
import { useEffect } from 'react';

function ServiceTemplate({ title, type, discription, image, onFeedbackOpen, onStartPopupOpen, id }) {

  function FeedBackAndMetrica({ type }) {
    switch (type) {
      case 'dev': {
        return sendMetriс('reachGoal', 'CLICK_ORDER_DEVELOPMENT')
      }
      case 'design': {
        return sendMetriс('reachGoal', 'CLICK_ORDER_DESIGN')
      }
      case 'seo': {
        return sendMetriс('reachGoal', 'CLICK_ORDER_MARKETING')
      }
      case 'support': {
        return sendMetriс('reachGoal', 'CLICK_ORDER_SUPPORT')
      }
      default:
        break;
    }
    onStartPopupOpen();
  }

  useEffect(() => { // Add this useEffect hook
    if(!id) return
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${id}`)) {
        const element = document.getElementById(id);
        if (element) {
            // Scroll the element into view
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }
}, []);

  return (
    <div className='service-box' id={id}>
      <div className="service">
        <div>
          <p className='service__title'>{title}</p>
          <div className={`service__block service__block_desktop service_bg_${type}`}>
            <div className='service__discription'>
              {discription.map((item, i) => (
                <div key={i} className='service__discription_text'>
                  <div className='discription__title-icon'>
                    <div>
                      <p className='discription__title'
                        dangerouslySetInnerHTML={{
                          __html: item.title,
                        }}
                      />
                      {item.transcription ?
                        <p className='discription__text'
                          dangerouslySetInnerHTML={{
                            __html: item.transcription,
                          }}
                        /> : null}
                    </div>
                    <div>{item.icon}</div>
                  </div>
                  <p className='discription__text'
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />
                </div>
              ))}
              <button onClick={onStartPopupOpen} className={`service__button service__button_yellow button_${type}`}>Продавайте с нами</button>
            </div>
          </div>

          <div className={`service__block service__block_mobile service_bg_${type}`}>
            <div className='service__description_mobile'>
              <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                  dynamicMainBullets: 1,
                }}
                mousewheel={{
                  forceToAxis: true,
                }}

                modules={[Mousewheel, Pagination]}
                preventInteractionOnTransition={true}
                className={`service-swiper pagination_color`}
                style={{ paddingBottom: '30px' }}
              >

                {discription.map((item, i) => (
                  <SwiperSlide key={item.title} className={`service-slide service-slide-${i}`}>
                    <div className='service__text_mobile'>
                      <div className='discription__title-icon_mobile'>
                        <div>
                          <p className='discription__title_mobile'
                            dangerouslySetInnerHTML={{
                              __html: item.title,
                            }}
                          />
                          {item.transcription ?
                            <p className='discription__text'
                              dangerouslySetInnerHTML={{
                                __html: item.transcription,
                              }}
                            /> : null}
                        </div>
                        <div>{item.icon}</div>
                      </div>
                      <p className={`discription__text_mobile`}
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <button onClick={FeedBackAndMetrica} className={`service__button service__button_yellow button_${type}`}>Продавайте с нами</button>
            </div>
          </div>
        </div>
        <img className='service__box-main-image service__box-main-image_type_desktop service__image' src={imageLaptop} alt='#'></img>
        <img className='service__box-main-image service__box-main-image_type_mobile service__image' src={imageMobile} alt='#'></img>
      </div>
    </div>
  );
}

export default ServiceTemplate