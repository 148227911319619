import './AI-Suite-Card.css';

const SuiteCard = ({ card }) => {

    return (
        <div className='suite-card'>
            {/*<span className='suite-card__num'>{card.num}</span>*/}
            <div className='suite-card__img suite-card__img_type_desktop'>
                {card.img}
            </div>
            <div className='suite-card__img suite-card__img_type_mobile'>
                {card.imgTablet}
            </div>
            <span className='suite-card__txt'
            dangerouslySetInnerHTML={{
                    __html: card.txt,
                  }}></span>

        </div>
    )
}

export default SuiteCard;