import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import './App.css';
import Header from '../Header/Header';
import Main from '../Main/Main';
import ModalCloser from "../ModalCloser/ModalCloser";
import Portfolio from "../Portfolio/Portfolio";
import { useState } from 'react';
import { Feedback } from '../Feedback/Feedback';
import { Success } from '../Feedback/Success/Success';
import Projects from "../Projects/Projects";
import { sendMetriс } from "../../assets/utils/utils";

import SmallFormPopup from "../SmallFormPopup/SmallFormPopup";
import HowItWork from "../HowItWork/HowItWork";
import Tarif from "../Tarif/Tarif";
import Numbers from "../Numbers/Numbers";
import HeaderPopup from "../Header/HeaderPopup/HeaderPopup";
import Marketplace from "../Marketplace/Marketplace";


function App() {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [isFeedbackOpened, setFeedbackOpened] = useState(false);
  const [isStartPopupOpen, setIsStartPopupOpen] = useState(false)

  const onCloseFeedbackHandler = () => {
    setFeedbackOpened(isFeedbackOpened => !isFeedbackOpened);
    navigate('/');
  }

  const onFeedbackOpen = () => {
    setFeedbackOpened(true)
  }

  function openStartPopup() {
    setIsStartPopupOpen(true)
  }

  function closeStartPopup() {
    setIsStartPopupOpen(false)
  }

  return (
    <>
      <div className="app">
        <Header onStartPopupOpen={openStartPopup}/>
        <Routes location={location || state?.backgroundLocation}>
          <Route path="/" element={<Main onFeedbackOpen={onFeedbackOpen} onStartPopupOpen={openStartPopup} isStartPopupOpen={isStartPopupOpen} />} />
          <Route path="/project/:id" element={<Portfolio />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/how-it-works" element={<HowItWork onStartPopupOpen={openStartPopup} />} />
          <Route path="/tarif" element={<Tarif onStartPopupOpen={openStartPopup} />} />
          <Route path="/marketplaces" element={<Marketplace onStartPopupOpen={openStartPopup} />} />
        </Routes>
        {state?.backgroundLocation && (
          <Routes>
            <Route path="/project/:id" element={<ModalCloser />} />
          </Routes>
        )}
        <Feedback isFeedbackOpened={isFeedbackOpened} onFeedbackOpen={onFeedbackOpen} setFeedbackOpened={setFeedbackOpened} onCloseHandler={onCloseFeedbackHandler} />
        <SmallFormPopup isOpen={isStartPopupOpen} onClose={closeStartPopup} />
        {location.pathname.includes('project') ? null : <Numbers />}
      </div>
    
    </>
  );
}

export default App;
