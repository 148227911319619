import { useEffect } from "react";
import {
  QuestionIcon,
  RhombShape,
} from "../../../assets/icon/marketplaceIcons";
import banner from "../../../assets/images/marketplace/main-img.webp";
import "./MarketHeading.css";

const ID = 'market-heading'
function MarketHeading({ onStartPopupOpen }) {

  useEffect(() => { // Add this useEffect hook
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${ID}`)) {
      const element = document.getElementById(ID);
      if (element) {
        // Scroll the element into view
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, []);

  return (
    <div className="market-heading" id={ID}>
      <div className="market-heading__content">
        <h3 className="market-heading__title">
          Маркет
          <br />
          плейсы <span className="market-heading__title_type_color">→</span>
        </h3>
        <p className="market-heading__subtitle">
          Запускаем ваш бизнес на маркетплейсах. Продвигаем товары. Повышаем
          продажи.
        </p>
        <div className="market-heading__btns-box">
          <button
            className="market-heading__main-btn"
            type="button"
            onClick={onStartPopupOpen}
          >
            <p className="market-heading__btn-text">Бесплатная консультация</p>
          </button>
          <button
            className="market-heading__ask-btn"
            type="button"
            onClick={onStartPopupOpen}
          >
            <div className="market-heading__icon-box">
              <QuestionIcon
                mainClassName="market-heading__ask-icon"
                strokeClassName="market-heading__ask-icon-stroke"
              />
            </div>
            <p className="market-heading__btn-text">Задать вопрос</p>
          </button>
        </div>
      </div>

      <img className="market-heading__main-img" src={banner} alt="" />
      <RhombShape
        mainClassName="market-heading__rhomb market-heading__rhomb_type_top-left"
        fillClassName="market-heading__rhomb-fill"
      />
      <RhombShape
        mainClassName="market-heading__rhomb market-heading__rhomb_type_top-right"
        fillClassName="market-heading__rhomb-fill"
      />
      <RhombShape
        mainClassName="market-heading__rhomb market-heading__rhomb_type_bottom-right"
        fillClassName="market-heading__rhomb-fill"
      />
    </div>
  );
}

export default MarketHeading;
