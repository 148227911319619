import './Decision.css';
import decisionPicDesktop from '../../assets/images/MainDecisionLaptop.png';
import decisionPicMobile from '../../assets/images/MainDecisionMobile.png';
import demo from '../../assets/images/demo.svg';
import ServiceNumberSide from '../Main/ServiceTemplate/ServiceNumberSide/ServiceNumberSide';
import tail from '../../assets/images/messageTail.svg'
import tailMobile from '../../assets/images/tailMobile.svg'
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useWindowSize from '../../assets/hooks/useWindowSize';

const DECISION_MESSAGES = [
    {
        title: `Уникальный интернет-магазин на уровне лидеров
        рынка, на создание которого вы не потратите силы —
        во всем помогут наши менеджеры.`,
        color: '#FF8585'
    },
    {
        title: `Ведение и продвижение онлайн-магазина в руках профессионалов.`,
        color: '#FFB84F'
    },
    {
        title: `Вы станете администратором своего магазина:
        личный кабинет для управления статистикой,
        заказами, каталогом товаров и многим другим.`,
        color: '#7AE448'
    },
]

const ID = 'main-decision'

const Decision = ({ isStartPopupOpen, onStartPopupOpen, isPlankOpen }) => {
    const { width } = useWindowSize()

    useEffect(() => { // Add this useEffect hook
        // Check if the URL contains '#main-intro'
        if (window.location.href.includes(`#${ID}`)) {
            const element = document.getElementById(ID);
            if (element) {
                // Scroll the element into view
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, []);

    return (
        <AnimatePresence>
            <motion.div
                className={`decision-box ${isStartPopupOpen ? 'decision-box_popup_close' : ''}`}
                initial={{
                    paddingTop: "0",
                }}
                animate={{
                    paddingTop: isPlankOpen ? width >= 1200 ? "80px" : width >= 900 ? "70px" : "90px" : "0",
                }}
                transition={{ duration: 0.3, type: "tween" }}
                id={ID}
            >
                <div className='decision'>

                    <div className='decision__box'>
                        <div className='decision__box-texts'>
                            <h1 className='decision__box-texts-heading'>
                                Решение!
                            </h1>
                            <div className='decision__box-messages__box'>
                                <div className='decision__box-messages'>
                                    {DECISION_MESSAGES.map((item, i) => {
                                        return (
                                            <p className='decision__box-messages__message'>
                                                <p className='decision__box-messages__message__title'>
                                                    Polza
                                                    <p className='decision__box-messages__message__time'>00:0{i + 1}</p>
                                                </p>
                                                {item.title}
                                                <img src={tail} className='decision__box-messages__message__tail' alt=''></img>
                                                <img src={tailMobile} className='decision__box-messages__message__tail_mobile' alt=''></img>
                                            </p>
                                        )
                                    })}

                                </div>
                                <button className='decision__box-button_pink' onClick={onStartPopupOpen}>
                                    <img src={demo} alt=''></img>
                                    Демо
                                </button>
                            </div>
                        </div>
                        {/*<img className='decision__box-main-image decision__box-main-image_type_tablet' src={decisionPicTablet} alt='' />*/}
                        {/* <img className='decision__box-main-image decision__box-main-image_type_mob' src={decisionPicMob} alt='' /> */}
                    </div>
                    <img className='decision__box-main-image decision__box-main-image_type_desktop' src={decisionPicDesktop} alt='' />
                    <img className='decision__box-main-image decision__box-main-image_type_mobile' src={decisionPicMobile} alt='' />
                </div>
            </motion.div>
        </AnimatePresence>

    )
}

export default Decision;