export const RhombShape = ({ mainClassName, fillClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="1743"
      height="1009"
      viewBox="0 0 1743 1009"
      fill="none"
    >
      <path
        className={fillClassName}
        d="M871.5 0L1743 504.5L871.5 1009L0 504.5L871.5 0Z"
      />
    </svg>
  );
};

export const QuestionIcon = ({ mainClassName, strokeClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
    >
      <path
        className={strokeClassName}
        d="M18.5645 18.5643C18.7846 17.6156 19.3291 16.7736 20.104 16.1837C20.8789 15.5938 21.8355 15.293 22.8086 15.3334C23.8879 15.2736 24.947 15.6431 25.7549 16.3613C26.5628 17.0794 27.0539 18.0879 27.1211 19.1668C27.1211 22.0492 23.0003 23.0001 23.0003 24.9168"
        stroke="#111859"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={strokeClassName}
        d="M25 30.0026C24.9995 30.3979 24.8818 30.7842 24.6619 31.1127C24.4419 31.4412 24.1295 31.6972 23.7642 31.8483C23.3988 31.9993 22.9969 32.0387 22.6092 31.9614C22.2215 31.8842 21.8653 31.6938 21.5858 31.4142C21.3062 31.1347 21.1158 30.7785 21.0386 30.3908C20.9613 30.0031 21.0007 29.6012 21.1517 29.2358C21.3028 28.8705 21.5588 28.5581 21.8873 28.3381C22.2158 28.1182 22.6021 28.0005 22.9974 28C23.2605 27.9997 23.5211 28.0512 23.7642 28.1518C24.0073 28.2523 24.2282 28.3998 24.4142 28.5858C24.6002 28.7718 24.7477 28.9927 24.8483 29.2358C24.9488 29.4789 25.0003 29.7395 25 30.0026Z"
        fill="#111859"
      />
      <path
        className={strokeClassName}
        d="M8.87705 32.8865C6.39729 29.347 5.33415 25.0067 5.89741 20.7218C6.46066 16.4369 8.6094 12.5188 11.9197 9.7404C15.2299 6.96202 19.4613 5.5252 23.7789 5.71343C28.0965 5.90166 32.1868 7.70126 35.2427 10.7572C38.2986 13.8131 40.0982 17.9034 40.2865 22.221C40.4747 26.5386 39.0379 30.77 36.2595 34.0802C33.4811 37.3905 29.563 39.5392 25.2781 40.1025C20.9933 40.6658 16.6529 39.6026 13.1134 37.1229L7.66674 38.3332L8.87705 32.8865Z"
        stroke="#111859"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DotsIcon = ({ mainClassName, fillClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
    >
      <circle className={fillClassName} cx="6" cy="7.5" r="4" fill="white" />
      <circle
        className={fillClassName}
        opacity="0.5"
        cx="6"
        cy="18.5"
        r="4"
        fill="white"
      />
      <circle
        className={fillClassName}
        opacity="0.6"
        cx="17"
        cy="7.5"
        r="4"
        fill="white"
      />
      <circle
        className={fillClassName}
        opacity="0.3"
        cx="17"
        cy="18.5"
        r="4"
        fill="white"
      />
    </svg>
  );
};

export const CheckIcon = ({ mainClassName, strokeClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        className={strokeClassName}
        d="M11.6667 3.5L5.25 9.91667L2.33333 7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MainArrowIcon = ({ mainClassName, fillClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="20"
      viewBox="0 0 70 20"
      fill="none"
    >
      <path
        className={fillClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1.80425C16 1.01881 15.1361 0.539968 14.47 0.956248L1.3568 9.152C0.730129 9.54367 0.730128 10.4563 1.3568 10.848L14.47 19.0438C15.136 19.46 16 18.9812 16 18.1958V1.80425Z"
        fill="#FFB84F"
      />
      <path
        className={fillClassName}
        d="M11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11L11 9ZM58.6667 10C58.6667 12.9455 61.0545 15.3333 64 15.3333C66.9455 15.3333 69.3333 12.9455 69.3333 10C69.3333 7.05449 66.9455 4.66667 64 4.66667C61.0545 4.66667 58.6667 7.05449 58.6667 10ZM11 11L64 11L64 9L11 9L11 11Z"
        fill="#FFB84F"
      />
    </svg>
  );
};

export const CurveArrowIcon = ({ mainClassName, fillClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="97"
      viewBox="0 0 46 97"
      fill="none"
    >
      <path
        className={fillClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 78.8042C30 78.0188 30.8639 77.54 31.53 77.9562L44.6432 86.152C45.2699 86.5437 45.2699 87.4563 44.6432 87.848L31.53 96.0438C30.864 96.46 30 95.9812 30 95.1958V78.8042Z"
        fill="#FFB84F"
      />
      <path
        className={fillClassName}
        d="M35 88C35.5523 88 36 87.5523 36 87C36 86.4477 35.5523 86 35 86V88ZM0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM35 86H12V88H35V86ZM7 81V6H5V81H7ZM12 86C9.23858 86 7 83.7614 7 81H5C5 84.866 8.13401 88 12 88V86Z"
        fill="#FFB84F"
      />
    </svg>
  );
};

export const MobileArrowIcon = ({ mainClassName, fillClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="32"
      viewBox="0 0 12 32"
      fill="none"
    >
      <path
        className={fillClassName}
        d="M5.3 24C5.3 24.3866 5.6134 24.7 6 24.7C6.3866 24.7 6.7 24.3866 6.7 24L5.3 24ZM6 7.73333C8.06186 7.73333 9.73333 6.06186 9.73333 4C9.73333 1.93814 8.06186 0.266666 6 0.266666C3.93814 0.266666 2.26667 1.93814 2.26667 4C2.26667 6.06186 3.93814 7.73333 6 7.73333ZM6.7 24L6.7 4L5.3 4L5.3 24L6.7 24Z"
        fill="#FFB84F"
      />
      <path
        className={fillClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3466 22C11.859 22 12.1714 22.5717 11.8998 23.0124L6.5532 31.689C6.29769 32.1037 5.70231 32.1037 5.4468 31.689L0.100204 23.0124C-0.171361 22.5717 0.141017 22 0.653405 22L11.3466 22Z"
        fill="#FFB84F"
      />
    </svg>
  );
};
