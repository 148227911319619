import { BoomPhoneCircleCoin, BoomPhoneCircleFirst, BoomPhoneCircleSecond } from '../../../assets/icon/projectIcon';
import screen1 from '../../../assets/img/Portfolio/BoomPhone/1.webp';
import screen2 from '../../../assets/img/Portfolio/BoomPhone/2.webp';
import bg1 from '../../../assets/img/Portfolio/BoomPhone/bg-1.webp';
import bg2 from '../../../assets/img/Portfolio/BoomPhone/bg-2.webp';
import bg3 from '../../../assets/img/Portfolio/BoomPhone/bg-3.webp';

import './BoomPhone.css';

const BoomPhone = () => {
    return (
        <>
        <div className='screen screen-boomphone'>
            <img src={bg1} alt='' className="bg-boomphone-1"/>
            <img className='screen screen-boomphone' alt='' src={screen1}></img>
            </div>
            <div className='screen screen-boomphone'>
            <img src={bg2} alt='' className="bg-boomphone-2"/>
            <img src={bg3} alt='' className="bg-boomphone-3"/>
            <img className='screen screen-boomphone' alt='' src={screen2}></img>
            </div>
        </>
    )
}
export default BoomPhone