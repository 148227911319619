import screen1 from '../../../assets/img/Portfolio/dr.epi/1.webp';
import screen2 from '../../../assets/img/Portfolio/dr.epi/2.webp';
import screen3 from '../../../assets/img/Portfolio/dr.epi/3.webp';
import screen3bg from '../../../assets/img/Portfolio/dr.epi/3bg.webp';
import roze1 from '../../../assets/img/Portfolio/dr.epi/roze1.webp';
import roze2 from '../../../assets/img/Portfolio/dr.epi/roze3.webp';
import roze3 from '../../../assets/img/Portfolio/dr.epi/roze2.webp';
import legs from '../../../assets/img/Portfolio/dr.epi/legs2.webp';

import './DrEpi.css';

const DrEpi= () => {
    return (
        <>
            <img className='roze roze-1' alt='' src={roze1}></img>
            <img className='roze roze-2' alt='' src={roze2}></img>
            <img className='roze roze-3' alt='' src={roze3}></img>
            <img className='legs' alt='' src={legs}></img>
            <img className='screen-epi' alt='' src={screen1}></img>
            <img className='screen-epi' alt='' src={screen2}></img>
            <div className='screen-epi-3bg'>
            <img className='screen-epi-3bg_img' alt='' src={screen3bg}></img>
            <img className='screen-epi screen-epi-3' alt='' src={screen3}></img>
            </div>
        </>
    )
}
export default DrEpi