import './Feedback.css';
import { feedbackText } from '../../assets/constants/feedback';
import { CrossFeedbackIcon, SuccessMonitorIcon } from '../../assets/icons/icons';
import { useEffect, useState } from 'react';

import { BottomLeftFigure, UpLeftFigure, UpRightFigure } from '../../assets/icons/feedback';

import validator from 'validator';
import Input from '../Input/Input';
import ButtonGroup from './ButtonGroup/ButtonGroup';
import mainApi from '../../assets/api/MainApi';

import MiniPreloader from '../MiniPreloader/MiniPreloader';
import { sendMetriс } from '../../assets/utils/utils';
import useWindowSize from '../../assets/hooks/useWindowSize';

export const Feedback = ({ onCloseHandler, isFeedbackOpened, }) => {
    const [step, setStep] = useState(0)

    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        types: [],

    })

    const windowSize = useWindowSize()
    const scrollForMarketing = (windowSize.width <= 400) ? 3100 : (windowSize.width <= 650) ? 5500 : (windowSize.width <= 800) ? 6000 : (windowSize.width <= 1100) ? 4660 : (windowSize.width <= 1200) ? 4500 : (windowSize.width <= 1500) ? 4700 : 5150
    const scrollForDesign = (windowSize.width <= 650) ? 4000 : (windowSize.width <= 800) ? 5000 : (windowSize.width <= 1200) ? 3500 : (windowSize.width <= 1500) ? 4000 : 4500

    useEffect(() => {
        handleSelectType(window.scrollY < 2000 ?
            {
                title: 'Создание сайта',
                type: 'website_create',
            } : (window.scrollY < scrollForDesign) ?
                {
                    title: 'Редизайн сайта',
                    type: 'redesign',
                } : (window.scrollY < scrollForMarketing) ?
                    {
                        title: 'SMM',
                        type: 'smm',
                    }
                    : {
                        title: 'Поддержка',
                        type: 'support',
                    }
        ); console.log(window.scrollY)
    }, [isFeedbackOpened])

    const [formValid, setFormValid] = useState({
        email: {},
        phone: {},
    })

    function handleChange(e) {

        const input = e.target;
        const name = input.name;
        let value = input.value;

        switch (name) {

            case 'email':
                if (!value) {
                    setFormValid(prevValue => ({
                        ...prevValue,
                        email: {
                            errorMassage: 'Введите email',
                            validState: false
                        }
                    }))
                }
                if (value.length >= 2) {
                    if (validator.isEmail(value)) {
                        setFormValid(prevValue => ({
                            ...prevValue,
                            email: {
                                errorMassage: '',
                                validState: true
                            }
                        }))
                    } else {
                        setFormValid(prevValue => ({
                            ...prevValue,
                            email: {
                                errorMassage: (!e.target.validity.valid ? 'Invalid email' : ''),
                                validState: false
                            }
                        }))
                    }
                }
                // e.target.validationMessage 
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value
                }))
                break;

            case 'phone':
                let inputValue = e.target.value.replace(/\D/g, '')
                let formattedInputValue = '';
                if (!inputValue) {
                    setForm(prevValue => ({
                        ...prevValue,
                        phone: '',
                    }))
                    setFormValid(prevValue => ({
                        ...prevValue,
                        phone: {
                            errorMassage: 'Можно вводить только цифры',
                            validState: false
                        },
                    }))
                }
                else {
                    if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
                        setFormValid(prevValue => ({
                            ...prevValue,
                            phone: {
                                errorMassage: '',
                                validState: false
                            },
                        }))
                        if (inputValue[0] === '9') inputValue = '7' + inputValue;

                        let firstSimbols = (inputValue[0] === '8') ? '8' : '+7';
                        formattedInputValue = firstSimbols + ' '

                        if (inputValue.length > 1) {
                            formattedInputValue += '(' + inputValue.substring(1, 4)
                        }
                        if (inputValue.length >= 5) {
                            formattedInputValue += ') ' + inputValue.substring(4, 7)
                        }
                        if (inputValue.length >= 8) {
                            formattedInputValue += '-' + inputValue.substring(7, 9)
                        }
                        if (inputValue.length >= 10) {
                            formattedInputValue += '-' + inputValue.substring(9, 11)
                        }
                        if (inputValue.length >= 11) {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: true
                                },
                            }))
                        } else {

                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: false
                                },
                            }))
                        }
                    } else {
                        formattedInputValue = '+' + inputValue.substring(0, 16)
                        if (inputValue.length >= 11) {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: true
                                },
                            }))
                        } else {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: false
                                },
                            }))
                        }
                    }

                    setForm(prevValue => ({
                        ...prevValue,
                        phone: formattedInputValue,
                    }))
                }
                break;

            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value
                }))
                break;
        }
    };

    function handlePhoneDelite(e) {
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length === 1) {
            setForm(prevValue => ({
                ...prevValue,
                phone: '',
            }))
        }
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length < 11) {
            setFormValid(prevValue => ({
                ...prevValue,
                phone: {
                    errorMessage: '',
                    validState: false
                }
            }));
        }
    };
    function hendleFormReset() {
        setForm({
            name: '',
            email: '',
            phone: '',
            message: '',
            types: [],
        })
        setFormValid({
            email: {},
            phone: {},
        })
    };

    const [isPreloaderVisible, setPreloaderVisible] = useState(false)

    function handleOnSubmit(evt) {
        evt.preventDefault()
        setPreloaderVisible(true)
        mainApi.sendContactForm({
            name: form.name,
            email: form.email,
            phone: form.phone && formValid.phone.validState ? form.phone.replace(/\D/g, '') : '',
            text: form.message,
            types: form.types.map((item) => item.type),
        })
            .then((res) => {
                setStep(1)
                hendleFormReset()
                console.log(res)
                sendMetriс('reachGoal', 'SEND_FEEDBACK_FORM')
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })

    };

    const isFormVlid = form.name && form.email && formValid.email.validState && form.phone && formValid.phone.validState && form.message && form.types.length > 0;


    function handleSelectType(item) {
        console.log(item)
        setForm(prevValue => ({
            ...prevValue,
            types: prevValue.types.filter((filter_item) => filter_item.type === item.type).length > 0 ? prevValue.types.filter((filter_item) => filter_item.type !== item.type) : [...prevValue.types, item]
        }))
    }


    function handleClose() {
        onCloseHandler()
        setTimeout(() => {
            setStep(0)
            hendleFormReset()
        }, 400);
    }

    return (
        <div className={`feedback-background ${isFeedbackOpened ? 'feedback-background_opened' : ''}`} onClick={() => handleClose()}>
            <div className='feedback' onClick={(e) => e.stopPropagation()}>
                {step === 0 ?
                    <>
                        <div className='feedback__up-left-figure'><UpLeftFigure /></div>
                        <div className='feedback__up-right-figure'><UpRightFigure /></div>
                        <div className='feedback__bottom-left-figure'><BottomLeftFigure /></div>

                        <div className='feedback__box'>

                            <div className='feedback__box-cross' onClick={() => handleClose()}>
                                <CrossFeedbackIcon />
                            </div>
                            <h2 className='feedback__box-heading'>Начать работу</h2>
                            <span className='feedback__box-text'>{feedbackText}</span>

                            <div className='feedback__box-btns'>
                                <ButtonGroup
                                    selectedTypes={form.types}
                                    handleSelect={handleSelectType}

                                />
                            </div>

                            <form className='feedback__box-form' onSubmit={handleOnSubmit}>
                                <Input
                                    label={'Имя'}
                                    value={form.name}
                                    handleChange={handleChange}
                                    name={'name'}
                                    inputMode='text'
                                    isRequired={true}
                                // error={setOpen({ ...isOpen, [i]: isOpen[i] ? !isOpen[i] : true })}
                                // isOpen={isOpen[i]}
                                />

                                <Input
                                    label={'Телефон'}
                                    value={form.phone}
                                    handleChange={handleChange}
                                    name={'phone'}
                                    inputMode='tel'
                                    error={formValid.phone}
                                    onKeyDown={handlePhoneDelite}
                                // isOpen={isOpen[i]}
                                />

                                <div className='feedback__box-form-input-box'>
                                    <Input
                                        label={'Эл.почта'}
                                        value={form.email}
                                        handleChange={handleChange}
                                        name={'email'}
                                        inputMode='email'
                                        error={formValid.email}
                                        required={true}
                                    // isOpen={isOpen[i]}
                                    />
                                </div>

                                <div className='feedback__box-form-input-box'>
                                    <Input
                                        label={'Несколько слов о проекте'}
                                        value={form.message}
                                        handleChange={handleChange}
                                        name={'message'}
                                        required={true}
                                    // isOpen={isOpen[i]}
                                    />
                                </div>
                                {isPreloaderVisible ?
                                    <MiniPreloader />
                                    :
                                    <button type='submit'
                                        className={`feedback__box-form-btn ${!isFormVlid ? 'feedback__box-form-btn_type_disabled' : ''}`}
                                    >Отправить заявку</button>}
                            </form>
                        </div>
                    </>
                    : null}

                {step === 1 ?
                    <>
                        <div className='success__up-left-figure'><UpLeftFigure /></div>
                        <div className='success__up-right-figure'><UpRightFigure /></div>
                        <div className='success__box'>
                            <div className='success__box-cross' onClick={() => handleClose()}>
                                <CrossFeedbackIcon />
                            </div>
                            <h2 className='success__box-result'>Отлично</h2>
                            <span className='success__box-details'>Ваша заявка отправлена.<br /> Наш менеджер скоро свяжется с вами.</span>
                            <div className='success__box-icon'><SuccessMonitorIcon /></div>
                            <button className='success__box-close-btn' type='button' onClick={() => handleClose()}>Закрыть</button>
                        </div>
                    </>
                    : null}

            </div >
        </div>
    )
};