import { useEffect, useState, useRef } from "react";
import "./Input.css";
import { TextareaAutosize } from "@mui/material";
import { motion } from "framer-motion";

function Input({
    index,
    label,
    boxType,
    value,
    name,
    type,
    inputMode = 'text',
    handleChange,
    isRequired,
    readOnly = false,
    icon,
    handleVisibility,
    error,
    isTextArea,
    maxRows,
    minRows,
    onKeyDown,
    isOpen
}) {

    const contentRef = useRef(null);

    const [isFocuced, setIsFocuced] = useState(false);

    useEffect(() => {
        Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
    }, [value]);

    function handleFocused() {
        setIsFocuced(true);
    };

    function handleUnfocused(evt) {
        const value = evt.target.value;
        Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
    };

    function handlePopupVisibility(evt) {
        handleVisibility(true, evt);
    };

    function handlePopupBlur(evt) {
        handleVisibility(false, evt);
    };

    function handleInputChange(evt) {
        index !== undefined ? handleChange(evt, index) : handleChange(evt);
    };

    function handlePasswordVisibility() {
        handleVisibility(name);
    };

    return (
        <motion.div
            className={`custom-input custom-input_type_${boxType ? boxType : name} ${(isFocuced || Boolean(value)) ? "custom-input_focused" : ""
                } ${readOnly ? "custom-input_disabled" : ""}`}
            onClick={
                name.includes("search")
                    ? (evt) => handlePopupVisibility(evt)
                    : undefined
            }
            onFocus={
                name.includes("search")
                    ? (evt) => handlePopupVisibility(evt)
                    : undefined
            }
            onBlur={
                name.includes("search") ? (evt) => handlePopupBlur(evt) : undefined
            }

            // initial={{
            //     paddingBottom: "14px",
            // }}
            // animate={{
            //     paddingBottom: !isOpen[name] ? "14px" : "0px",
            // }}
            // transition={{ duration: 0.7, type: "tween" }}
        >
            <label className="custom-input__label" htmlFor={name}>
                {label}
            </label>

            {/* {isTextArea
                ?
                <TextareaAutosize
                    className={`custom-input__input custom-input__input_text-area ${icon ? "custom-input__input_type_with-icon" : ""
                        } ${Boolean(value) ? "custom-input__input_valid" : ""} ${error !== undefined && !error.validState && Boolean(value)
                            ? "custom-input__input_error"
                            : ""
                        }`}
                    id={name}
                    name={name}
                    type={type}
                    inputMode={inputMode}
                    value={value || ""}
                    required={isRequired}
                    readOnly={readOnly}
                    autoComplete="new-password"
                    onChange={handleInputChange}
                    onClick={!readOnly ? handleFocused : undefined}
                    onFocus={!readOnly ? handleFocused : undefined}
                    onBlur={handleUnfocused}
                    maxRows={maxRows ? maxRows : 10}
                    minRows={minRows ? minRows : 1}
                />
                : */}
            <input
                className={`custom-input__input ${Boolean(value) ? "custom-input__input_valid" : ""} ${error !== undefined && !error.validState && Boolean(value)
                    ? "custom-input__input_error"
                    : ""
                    }`}
                id={name}
                name={name}
                type={type}
                inputMode={inputMode}
                value={value || ""}
                required={isRequired}
                readOnly={readOnly}
                autoComplete='new-password'
                onChange={handleInputChange}
                onClick={!readOnly ? handleFocused : undefined}
                onFocus={!readOnly ? handleFocused : undefined}
                onBlur={handleUnfocused}
                // onKeyDown={(e) => {
                //     if (onKeyDown) {
                //         onKeyDown(e)
                //     }
                // }}
            />

            {/*  Здесьнадо делать проверку про состоянию isOpen &&  */} 

            {error !== undefined && Boolean(!error.validState) && Boolean(value) ? (
                <motion.span className="custom-input__error" 
                // ref={contentRef}
                //     initial={{
                //         height: "0px",
                //         marginBottom: "0px",
                //         opacity: 0,
                //         visibility: "hidden",
                //     }}
                //     animate={{
                //         height: isOpen ? contentRef.current.scrollHeight + "px" : "0px",
                //         // marginBottom: isOpen ? "40px" : "0px",
                //         opacity: isOpen ? 1 : 0,
                //         visibility: isOpen ? "visible" : "hidden",
                //     }}
                //     transition={{ duration: 0.3, type: "tween" }}
                    >Введите корректные данные</motion.span>
            ) : null}

        </motion.div>
    );
}

export default Input;

