import { useEffect, useState } from 'react';
import './PortfolioTemplate.css';
import OutsideModalCloser from "../../OutsideModalCloser/OutsideModalCloser";
import { LikeIcon, LikeIconMobile, ShareIcon, ShareIconMobile } from '../../../assets/icon/mainIcon';
import { getProject, getProjectDesign, getProjectGroup } from '../../../assets/utils/constants';
import { useParams, useNavigate } from 'react-router-dom';
import useEscapeKey from '../../../assets/hooks/useEscapeKey';
import ScrollToTop from '../../../assets/hooks/scrollToTop';
import { Helmet } from 'react-helmet';

const PortfolioTemplate = ({ children, type }) => {
    const { id } = useParams();
    const project = (getProject(id) || getProjectDesign(id) || getProjectGroup(id));
    const { text, name, baner, field, field_discription } = project;
    let task = text.join(` • `);
    const [isCopied, setCopied] = useState(false);

    function handleShare() {
        if (isCopied) return
        let text = window.location.href
        //let formated_text = `${isAdvertPage ? breederInfo.share_text_advert.replace('{BREED_NAME}', product.breed_data.title.en) : breederInfo.share_text.replace('{BREEDER_NAME}', breeder.name)} - ${text}`
        if (navigator.share === undefined) {
            var textArea = document.createElement("textarea");
            textArea.style.position = 'fixed';
            textArea.style.top = 0;
            textArea.style.left = 0;
            textArea.style.width = '2em';
            textArea.style.height = '2em';
            textArea.style.padding = 0;
            textArea.style.border = 'none';
            textArea.style.outline = 'none';
            textArea.style.boxShadow = 'none';
            textArea.style.background = 'red';
            textArea.value = window.location.href;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'успешно' : 'безуспешно :( ';
                window.alert('Копирование ссылки прошло ' + msg);
                console.log('Копирование ссылки прошло ' + msg);
                setCopied(true)
                setTimeout(() => {
                    setCopied(false)
                }, 750);
            } catch (err) {
                console.log('Oops, unable to copy');
            }
            // document.body.removeChild(window.location.href);
            document.body.removeChild(textArea);

        } else {
            navigator.share({ url: text })
                .then()
                .catch((err) => (console.log(err)))
        }
    }
    let navigate = useNavigate();
    const handleClose = () => {
        navigate(-1);
    };

    useEscapeKey(handleClose)

    useEffect(() => {
        if (!project) {
            return
        }
        window.dataLayer.push({
            ecommerce: {
                currencyCode: "RUB",
                detail: {
                    products: [
                        {
                            id: project.id,
                            name: `${project.name}`,
                            category: 'Проекты',
                        },
                    ],
                },
            }
        })
    }, [project]);

    return (
        <div className={`project__page project__page-${id}`}>
            <Helmet>
                <title>Polza — {project.name}</title>
            </Helmet>
            <ScrollToTop />
            <OutsideModalCloser />
            <div className="project__header">
                <div>
                    <p className={`project__name project-name-${id}`}>{name}</p>
                    <p className={`project__tasks project__tasks-${id}`}>{task}</p>
                </div>
                <div className="button__block">
                    {/* <button className={`project__button-like`}><LikeIcon mainClassName={`like-icon`}/><LikeIconMobile mainClassName='like-icon-mobile'/>Like</button>*/}
                    <button className="project__button-share" type='buton' onClick={handleShare}><ShareIcon strokeClassName='share-icon' mainClassName='share-icon' /><ShareIconMobile strokeClassName='share-icon-mobile' mainClassName='share-icon-mobile' /></button>
                </div>
            </div>
            <img className={`bg bg-${id}`} src={baner} alt=''></img>
            <div className={`about-project__block about-project__block-${id} about-project__block-bg-${id}`}>
                <div className={`about about-${id}`}>
                    <p className={`about__title about-${id}`}>{name} —</p>
                    <p className={`about__title about-${id}`}>{field}</p>
                    <p className={`about__text about-${id}`}>{field_discription}</p>
                </div>
                {children}
            </div>
        </div>
    );
};

export default PortfolioTemplate;