
import './Projects.css';
import logo from '../../assets/img/Portfolio/projectpageLogo.png'
import logoMobile from '../../assets/img/Portfolio/projectpageLogoMobile.png'
import { ArrowIcon } from '../../assets/icon/mainIcon';
import ScrollToTop from '../../assets/hooks/scrollToTop';
import { PROJECTS_GROUP } from '../../assets/utils/constants';
import { Helmet } from 'react-helmet';
import CardTemplate from '../CardTemplate/CardTemplate';


const Projects = () => {
    function handleUpper() {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    return (
        <div className='block-project'>
            <Helmet>
                <title>Polza - Проекты</title>
            </Helmet>
            <ScrollToTop />
            <div className='project-page'>
                <div className='title-logo_block'>
                    <p className='projects__title'>Наши<br></br>
                        проекты
                        <span className='projects__subtitle'>Интернет-магазины, которые приносят пользу</span>
                    </p>
                </div>
                <div className='projects__cards'>
                    {PROJECTS_GROUP.map((item, i) => (
                        <CardTemplate key={i} company={item.id} bg={item.bg} logo={item.logo} title={item.name} />
                    ))}
                </div>
                <button onClick={handleUpper} className='upper-button'><ArrowIcon /></button>
                <img className='project-page__logo project-page__logo_desktop' src={logo} alt=''></img>
                <img className='project-page__logo project-page__logo_mobile' src={logoMobile} alt=''></img>
            </div>
        </div>
    );
};

export default Projects;