
import BigBro from './BigBro/BigBro';
import PortfolioTemplate from './PortfolioTemplate/PortfolioTemplate';
import './Portfolio.css';
import { useParams } from 'react-router-dom';
import Alclic from './Alclic/Alclic';
import Divanchik from './Divanchik/Divanchik';
import BeYou from './BeYou/Beyou';
import GeneticClinic from './GeneticClinic/GeneticClinic';
import SBA from './SBA/SBA';
import Kamnizdes from './Kamnizdes/Kamnizdes';
import ArtMaster from './ArtMaster/ArtMaster';
import DrEpi from './DrEpi/DrEpi';
import BoomPhone from './BoomPhone/BoomPhone';
import BoomSite from './BoomSite/BoomSite';
import BrainSpace from './BrainSpace/BrainSpace';
import Kover from './Kover/Kover';
import Marker from './Marker/Marker';
import GurmeKosher from './GurmeKosher/GurmeKosher';


function getTemplate(id) {
  switch (id) {
    case 'bigbro': {
      return <BigBro />
    }
    case 'alclic': {
      return <Alclic />
    }
    case 'divanchik': {
      return <Divanchik />
    }
    case 'geneticclinic': {
      return <GeneticClinic />
    }
    case 'kamnizdes': {
      return <Kamnizdes />
    }
    case 'artmaster': {
      return <ArtMaster />
    }
    case 'drepi': {
      return <DrEpi />
    }
    case 'boomphone': {
      return <BoomPhone />
    }
    case 'boomsite': {
      return <BoomSite />
    }
    case 'brainspace': {
      return <BrainSpace />
    }
    case 'sba': {
      return <SBA />
    }
    case 'beyou': {
      return <BeYou />
    }
    case 'kosher': {
      return <GurmeKosher />
    }
    case 'marker': {
      return <Marker />
    }
    case 'kover': {
      return <Kover />
    }
    default:
      break;
  }
}


const Portfolio = () => {
  const { id } = useParams();

  return (
    <div className='portfolio'>
      <PortfolioTemplate>
        {getTemplate(id)}
      </PortfolioTemplate>
    </div>
  );
};

export default Portfolio;