import './Integration.css';
import integrationPicDesktop from '../../assets/images/IntegrationMainBg.png';
import demo from '../../assets/images/demo.svg';
import ServiceNumberSide from '../Main/ServiceTemplate/ServiceNumberSide/ServiceNumberSide';
import kassa from '../../assets/images/kassa.svg';
import onec from '../../assets/images/1c.svg';
import sber from '../../assets/images/sber.png';
import cdek from '../../assets/images/cdek.svg';
import yametrica from '../../assets/images/metrica.png';
import per from '../../assets/images/per.png'
import { useEffect } from 'react';
const INTEGRATION_LOGOS = [
    {
        logo: sber,
        area: 'sber'
    },
    {
        logo: onec,
        area: '1c'
    },
    {
        logo: cdek,
        area: 'cdek'
    },
    {
        logo: kassa,
        area: 'kassa'
    },
    {
        logo: yametrica,
        area: 'metrica'
    },
    {
        logo: per,
        area: 'per'
    },
]
const ID = 'main-integration'
const Integration = () => {
    useEffect(() => { // Add this useEffect hook
        // Check if the URL contains '#main-intro'
        if (window.location.href.includes(`#${ID}`)) {
            const element = document.getElementById(ID);
            if (element) {
                // Scroll the element into view
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, []);

    return (
        <div className='integration-box' id={ID}>
            <div className='integration'>

                <div className='integration__box'>
                    <div className='integration__box-texts'>
                        <h1 className='integration__box-texts-heading'>
                            Интеграции с пользой!
                        </h1>
                        <h2 className='integration__box-texts-subheading'>
                            Мы осуществляем ряд интеграций,
                            которые способствуют улучшению продвижения
                            онлайн-магазина и росту продаж, а также упрощают отслеживание качества работы:
                        </h2>
                    </div>
                    <div className='integration__box-logos'>
                        {INTEGRATION_LOGOS.map((item, i) => {
                            return (
                                <img className='integration__box-logos__logo' style={{ gridArea: item.area }} src={item.logo}></img>
                            )
                        })}
                    </div>
                    <img className='integration__box-main-image integration__box-main-image_type_desktop' src={integrationPicDesktop} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Integration;