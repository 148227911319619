
import { useLocation } from "react-router-dom";
import './OutsideModalCloser.css';
import '../Header/HeaderPopup/HeaderPopup.css';
import Header from "../Header/Header";

const OutsideModalCloser = () => {
  const { state } = useLocation();
  const isInsideModal = state?.backgroundLocation;

  return (
    <div >
      {!isInsideModal ? (
        <Header />
      ) : null}
    </div>
  );
};

export default OutsideModalCloser;