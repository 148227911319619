import screen1 from '../../../assets/img/Portfolio/Kamnizdes/screen-11.webp';
import screen2 from '../../../assets/img/Portfolio/Kamnizdes/screen-22.webp';
import screen3 from '../../../assets/img/Portfolio/Kamnizdes/screen-33.webp';

import './Kamnizdes.css';

const Kamnizdes = () => {
    return (
        <>
            <img className='screen' alt='' src={screen1}></img>
            <img className='screen' alt='' src={screen2}></img>
            <img className='screen' alt='' src={screen3}></img>
        </>
    )
}
export default Kamnizdes