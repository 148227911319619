import './Numbers.css';

import CountUp from 'react-countup';

import Footer from '../Footer/Footer';
import logo from '../../assets/images/FooterLogo.png'


const Numbers = () => {
    return (
        <div className='numbers'>
            <div className='numbers__box'>
              
                   <img className='numbers__box-icon-box' src={logo}></img>
               
                 <div className='numbers__box-detail-box'>
                    {<CountUp start={0} end={32} duration={3} delay={0} enableScrollSpy={true} scrollSpyOnce={true}
                        className='numbers__box-detail-box-num numbers__box-detail-box-num_type_second' />}
                    <span className='numbers__box-detail-box-txt numbers__box-detail-box-txt_type_second'>специалиста в команде</span>
                </div>
                <div className='numbers__box-detail-box'>
                    {<CountUp start={1000} end={1259} duration={3} delay={0} separator='' enableScrollSpy={true} scrollSpyOnce={true}
                        className='numbers__box-detail-box-num numbers__box-detail-box-num_type_first' />}
                    <span className='numbers__box-detail-box-txt numbers__box-detail-box-txt_type_first'>сайтов в портфолио</span>
                </div>

                <div className='numbers__box-detail-box'>
                    {<CountUp start={20000} end={21400} duration={3} delay={0} separator='' enableScrollSpy={true} scrollSpyOnce={true}
                        className='numbers__box-detail-box-num numbers__box-detail-box-num_type_third' />}
                    <span className='numbers__box-detail-box-txt numbers__box-detail-box-txt_type_third'>чашек кофе выпито со дня основания</span>
                </div>
            
            </div>
            
            <Footer />
        </div >
    )
};

export default Numbers;