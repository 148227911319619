import { useEffect, useState } from "react";
import "./PopupInput.css";

function PopupInput({
  label,
  value,
  name,
  type,
  inputMode = "text",
  onChange,
  isRequired,
  readOnly = false,
  error,
  onKeyDown,
  refObj,
}) {
  const [isFocuced, setIsFocuced] = useState(false);

  useEffect(() => {
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }, [value]);

  function handleFocused() {
    setIsFocuced(true);
  }

  function handleBlur(evt) {
    const value = evt.target.value;
    Boolean(value) ? setIsFocuced(true) : setIsFocuced(false);
  }

  return (
    <div
      className={`popup-input ${isFocuced || Boolean(value) ? "popup-input_focused" : ""
        } ${readOnly ? "popup-input_disabled" : ""}`}
    >
      <label className="popup-input__label" htmlFor={name}>
        {label}
      </label>
      <input
        className="popup-input__input"
        id={name}
        name={name}
        type={type}
        inputMode={inputMode}
        value={value || ""}
        required={isRequired}
        readOnly={readOnly}
        autoComplete="new-password"
        onChange={onChange}
        onClick={!readOnly ? handleFocused : undefined}
        onFocus={!readOnly ? handleFocused : undefined}
        onBlur={handleBlur}
        onKeyDown={onKeyDown ? onKeyDown : undefined}
        ref={refObj ? refObj : null}
      />

      {error !== undefined && Boolean(!error.validState) && Boolean(value) ? (
        <span className="popup-input__error">{error.errorMessage}</span>
      ) : null}
    </div>
  );
}

export default PopupInput;
