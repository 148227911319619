import screen1 from '../../../assets/img/Portfolio/kosher/Screen-1.webp';
import screen2 from '../../../assets/img/Portfolio/kosher/Screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/kosher/Screen-3.webp';
import screen4 from '../../../assets/img/Portfolio/kosher/Screen-4.webp';
import screen5 from '../../../assets/img/Portfolio/kosher/Screen-5.webp';
import screen6 from '../../../assets/img/Portfolio/kosher/Screen-6.webp';
import logo from '../../../assets/img/Portfolio/kosher/logo.webp';
import circle from '../../../assets/img/Portfolio/kosher/circle.webp'
import './GurmeKosher.css';

const GurmeKosher = () => {
    return (
        <>
            <img className='screen screen_kosher' alt='' src={screen1}></img>
            <div className='screen screen__box_kosher-screen-and-circle'>
                <img className='screen screen_kosher' alt='' src={screen2}></img>
                <img className='kosher__circle kosher__circle_first' alt='' src={circle}></img>
            </div>
            <img className='screen screen_kosher' alt='' src={screen3}></img>
            <div className='kosher__box_screen-mobile'>
                <img className='screen kosher__screen-mobile ' alt='' src={screen4}></img>
                <img className='screen kosher__screen-mobile' alt='' src={screen5}></img>
                <img className='screen kosher__screen-mobile_logo' alt='' src={logo}></img>
                <img className='kosher__circle kosher__circle_second' alt='' src={circle}></img>
            </div>
            <img className='screen screen_kosher' alt='' src={screen6}></img>
        </>
    )
}
export default GurmeKosher