import { NavLink, useLocation } from "react-router-dom";
import { ARTICLES_LINK, CONTACT_EMAIL, CONTACT_PHONE } from "../../../assets/constants/constants";
import Footer from "../../Footer/Footer";
import demo from '../../../assets/images/main/header-popup-demo.png'

function HeaderPopup({ onStartPopupOpen, isHeaderPopupOpen, setHeaderPopupOpen }) {

    const onMenuBtnHandler = () => { setHeaderPopupOpen(!isHeaderPopupOpen) }
    const location = useLocation()
    const is_marketplace_location = location.pathname === "/marketplaces"


    return (
        <div className={`header-popup ${isHeaderPopupOpen ? "header-popup_opened" : ""}`}>
            <div className='header-popup__box'>
                <div className='header-popup__box-nav-links'>
                    <NavLink onClick={onMenuBtnHandler} to='/' className={({ isActive }) =>
                        (isActive ? "header-popup__box-nav-links-link_type_active" : "header-popup__box-nav-links-link_type_disabled")}>
                        <span className='header-popup__box-nav-links-link-txt'>Главная</span>
                        <div className='header-popup__box-nav-links-dot'></div>
                    </NavLink>
                    <NavLink onClick={onMenuBtnHandler} to='/how-it-works' className={({ isActive }) =>
                        (isActive ? "header-popup__box-nav-links-link_type_active" : "header-popup__box-nav-links-link_type_disabled")}>
                        <span className='header-popup__box-nav-links-link-txt'>Как это работает?</span>
                        <div className='header-popup__box-nav-links-dot'></div>
                    </NavLink>
                    <NavLink onClick={onMenuBtnHandler} to='/tarif' className={({ isActive }) =>
                        (isActive ? "header-popup__box-nav-links-link_type_active" : "header-popup__box-nav-links-link_type_disabled")}>
                        <span className='header-popup__box-nav-links-link-txt'>Тариф</span>
                        <div className='header-popup__box-nav-links-dot'></div>
                    </NavLink>
                    <NavLink onClick={onMenuBtnHandler} to='/marketplaces' className={({ isActive }) =>
                        (isActive ? "header-popup__box-nav-links-link_type_active" : "header-popup__box-nav-links-link_type_disabled")}>
                        <span className='header-popup__box-nav-links-link-txt'>Для маркетплейсов</span>
                        <div className='header-popup__box-nav-links-dot'></div>
                    </NavLink>
                    {/* <NavLink onClick={onMenuBtnHandler} to='/projects' className={({ isActive }) =>
                        (isActive ? "header-popup__box-nav-links-link_type_active" : "header-popup__box-nav-links-link_type_disabled")}>
                        <span className='header-popup__box-nav-links-link-txt'>Проекты</span>
                        <div className='header-popup__box-nav-links-dot'></div>
                    </NavLink> */}
                    <a href={ARTICLES_LINK} className={'header-popup__box-nav-links-link_type_disabled'} target='_blank' rel='noreferrer'>
                    <span className='header-popup__box-nav-links-link-txt'>Полезные статьи</span>
                    </a>
                    {/* <NavLink onClick={onMenuBtnHandler} to='/faq' className={({ isActive }) =>
                    (isActive ? "header-popup__box-nav-links-link_type_active" : "header-popup__box-nav-links-link_type_disabled")}>
                    <span className='header-popup__box-nav-links-link-txt'>FAQ</span>
                    <div className='header-popup__box-nav-links-dot'></div>
                    </NavLink>*/}
                </div>
                {!is_marketplace_location
                    ?
                    <img alt='' src={demo} className='header-popup__demo' onClick={onStartPopupOpen}></img>
                    :
                    null
                }
                <div className='header-popup__box-contacts'>
                    <a className='header-popup__box-contacts-box'
                        href={`tel:${CONTACT_PHONE.formated_value}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M21.703 19.133L20.402 17.832C19.752 17.1821 18.6984 17.1821 18.0495 17.832L17.0269 18.8546C16.7984 19.0831 16.4524 19.1596 16.1574 19.0299C14.6756 18.381 13.2115 17.3972 11.9061 16.0918C10.6062 14.7919 9.62574 13.3345 8.97579 11.8583C8.84048 11.5533 8.91923 11.195 9.15547 10.9588L10.0716 10.0426C10.8158 9.29843 10.8158 8.24587 10.1659 7.59593L8.86488 6.29493C7.99865 5.42871 6.5945 5.42871 5.72828 6.29493L5.00513 7.01697C4.18327 7.83883 3.84055 9.02448 4.06237 10.2001C4.61028 13.0983 6.29393 16.2715 9.01018 18.9877C11.7264 21.7039 14.8996 23.3876 17.7978 23.9355C18.9734 24.1573 20.1591 23.8146 20.981 22.9927L21.703 22.2707C22.5692 21.4045 22.5692 20.0003 21.703 19.133Z" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15.0886 8.46452C16.2321 8.449 17.3812 8.87268 18.2541 9.74556" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21.3907 6.60892C19.6505 4.86871 17.369 3.99805 15.0886 3.99805" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.5349 12.9108C19.5505 11.7673 19.1268 10.6182 18.2539 9.74536" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21.3875 6.60889C23.1277 8.3491 23.9983 10.6306 23.9983 12.9109" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span className='header-popup__box-contacts-box-number'>
                            {CONTACT_PHONE.formated_value}
                        </span>
                    </a>
                    <a className='header-popup__box-contacts-box'
                        href={`mailto:${CONTACT_EMAIL}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M19.5573 6H8.44615C5.99155 6 4.00171 7.91025 4.00171 10.2667V17.7333C4.00171 20.0897 5.99155 22 8.44615 22H19.5573C22.0119 22 24.0017 20.0897 24.0017 17.7333V10.2667C24.0017 7.91025 22.0119 6 19.5573 6Z" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.44678 10.8145L13.1182 12.7506C13.3973 12.8663 13.6984 12.9262 14.0029 12.9267C14.3074 12.9271 14.6087 12.868 14.8882 12.7532L19.5569 10.8341" stroke="#50FFAB" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span className='header-popup__box-contacts-box-email'>
                            {CONTACT_EMAIL}
                        </span>
                    </a>
                    {/*<a onClick={() => sendMetriс('reachGoal', 'CLICK_EMAIL')} className='header-popup__box-contacts-box' href={`mailto:${CONTACT_EMAIL}`}>
                     <PopupHeaderEmailIcon />
                     <span className='header-popup__box-contacts-box-email'>{CONTACT_EMAIL}</span>
                     </a>*/}
                </div>
            </div>
            <div className='header-popup__box-rhomb header-popup__box-rhomb_type_big'>
                <svg xmlns="http://www.w3.org/2000/svg" width="263" height="328" viewBox="0 0 263 328" fill="none">
                    <path d="M-20 0L263 164L-20 328L-303 164L-20 0Z" fill="#171F67" />
                </svg>
            </div>
            <div className='header-popup__box-rhomb header-popup__box-rhomb_type_small-dark'>
                <svg xmlns="http://www.w3.org/2000/svg" width="254" height="328" viewBox="0 0 254 328" fill="none">
                    <path opacity="0.5" d="M283 0L566 164L283 328L0 164L283 0Z" fill="#0B1149" />
                </svg>
            </div>
            <div className='header-popup__box-rhomb header-popup__box-rhomb_type_small-light'>
                <svg xmlns="http://www.w3.org/2000/svg" width="252" height="311" viewBox="0 0 252 311" fill="none">
                    <path d="M268.5 0L537 155.5L268.5 311L0 155.5L268.5 0Z" fill="#171F67" />
                </svg>
            </div>
            <Footer />
        </div>
    )
}

export default HeaderPopup;