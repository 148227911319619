import './Intro.css';
import IntroPicDesktop from '../../assets/images/MainIntroLaptop.png';
import IntroPicMobile from '../../assets/images/MainIntroMobile.png';
import quest from '../../assets/images/quest.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';

const ID = `main-intro`

const Intro = ({ onStartPopupOpen, isPlankOpen }) => {
    useEffect(() => { // Add this useEffect hook
        // Check if the URL contains '#main-intro'
        if (window.location.href.includes(`#${ID}`)) {
            const element = document.getElementById(ID);
            if (element) {
                // Scroll the element into view
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, []);

    return (
        <AnimatePresence>
            <motion.div
                className='intro-box'
                initial={{
                    marginBottom: "0px",

                }}
                animate={{
                    marginBottom: !isPlankOpen ? "-134px" : "0px",
                }}
                transition={{ duration: 0.3, type: "tween" }}
                id={ID}
            >
                <div className='intro'>
                    <div className='intro__box'>
                        <div className='intro__box-texts'>
                            <h1 className='intro__box-texts-heading'>
                                Первый шаг <br ></br>к Вашему интернет-магазину
                            </h1>
                            <h2 className='intro__box-texts-subheading'>Polza: бизнес в сети для каждого </h2>
                            <div className='intro__box-button'>
                                <button className='intro__box-button_purple' onClick={onStartPopupOpen}>Получить бесплатный пробный период</button>
                                {/* <button className='intro__box-button_quest' onClick={onStartPopupOpen}>
                                    <img className='intro__box-button_quest-logo' src={quest} alt='' />
                                    Задать вопрос
                                </button> */}
                            </div>
                        </div>
                        {/*<img className='intro__box-main-image intro__box-main-image_type_tablet' src={IntroPicTablet} alt='' />*/}
                        {/* <img className='intro__box-main-image intro__box-main-image_type_mob' src={IntroPicMob} alt='' /> */}
                    </div>
                    <img className='intro__box-main-image intro__box-main-image_type_desktop' src={IntroPicDesktop} alt='' />
                    <img className='intro__box-main-image intro__box-main-image_type_mobile' src={IntroPicMobile} alt='' />
                </div>
            </motion.div>
        </AnimatePresence>

    )
}

export default Intro;