import './CardTemplate.css';
import { Link, useLocation } from "react-router-dom";

function CardTemplate({ company, logo, title, bg }) {

  let location = useLocation();
  return (
    <Link to={`/project/${company}`} state={{ backgroundLocation: location }}
      style={{ gridArea: company }} className={`company__project_card company__project_card-${company}`}>
      {logo ?
        <div className='company__project_logo'>
          {logo}
        </div> : ''}
      {bg ?
        <img src={bg} alt='' className='company__project_bg'></img>
        : ''}
      <p className='company__project_title'>{title}</p>
    </Link>
  );
}

export default CardTemplate