import { useEffect } from "react";
import PlankContainer from "../PlankContainer/PlankContainer";
import "./RatePlank.css";

const ID = 'main-rate-plank'
function RatePlank({ onStartPopupOpen }) {

  useEffect(() => { // Add this useEffect hook
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${ID}`)) {
        const element = document.getElementById(ID);
        if (element) {
            // Scroll the element into view
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }
}, []);

  return (
    <PlankContainer type="rate">
      <div className="rate-plank" id={ID}>
        <div className="rate-plank__title-block">
          <p className="rate-plank__title">
            Тариф
            <br />
            <span className="rate-plank__title_type_uppercase">С Пользой!</span>
          </p>
          <div className="rate-plank__price-block">
            <p className="rate-plank__price">18000</p>
            <p className="rate-plank__price rate-plank__price_type_small">
              ₽/мес.
            </p>
          </div>
        </div>
        <p className="rate-plank__text">
          Полная упаковка интернет-магазина, а также его продвижение и
          поддержка. В тариф входят полезные услуги, уникальные на рынке.
        </p>
        <button
          className="rate-plank__btn"
          type="button"
          onClick={onStartPopupOpen}
        >
          Начать
        </button>
      </div>
    </PlankContainer>
  );
}

export default RatePlank;
