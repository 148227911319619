import React, { useState } from "react";
import "../Feedback.css";
import { DEFAULT_SERVICE_TYPES } from "../../../assets/utils/constants";

const ButtonGroup = ({ selectedTypes, handleSelect }) => {

    return (
        <>
            {DEFAULT_SERVICE_TYPES.map((item, i) => (
                <button
                    key={i}
                    name={item.title}
                    type="button"
                    onClick={() => handleSelect(item)}
                    className={`feedback__box-btns-btn ${selectedTypes && selectedTypes.filter((filter_item) => filter_item.type === item.type).length > 0 ? 'feedback__box-btns-btn_type_active' : ''}`}
                >
                    {item.title}
                </button >
            ))}
        </>
    );
};

export default ButtonGroup;