import screen from '../../../assets/img/Portfolio/divanchik/Screen-1.webp';
import screen2 from '../../../assets/img/Portfolio/divanchik/Screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/divanchik/Screen-3.webp';
import screen4 from '../../../assets/img/Portfolio/divanchik/Screen-4.webp';
import bed1 from '../../../assets/img/Portfolio/divanchik/bed-1.webp';
import bed2 from '../../../assets/img/Portfolio/divanchik/bed-2.webp';
import bed3 from '../../../assets/img/Portfolio/divanchik/bed-3.webp';
import bed4 from '../../../assets/img/Portfolio/divanchik/bed-4.webp';
import phone1 from '../../../assets/img/Portfolio/divanchik/phone-1.webp';
import phone2 from '../../../assets/img/Portfolio/divanchik/phone-2.webp';
import phone3 from '../../../assets/img/Portfolio/divanchik/phone-3.webp';

import './Divanchik.css';

const Divanchik = () => {
    return (
        <>
            <img className='screen' alt='' src={screen}></img>
            <img className='screen' alt='' src={screen2}></img>
            <div className='phone-block'>
                <img className='phone-divan' alt='' src={phone1}></img>
                <img className='phone-divan' alt='' src={phone2}></img>
                <img className='phone-divan' alt='' src={phone3}></img>
            </div>
            <img className='screen' alt='' src={screen3}></img>
            <div className='grid-bed'>
                <img className='img-bed' alt='' src={bed1}></img>
                <img className='img-bed' alt='' src={bed2}></img>
                <img className='img-bed' alt='' src={bed3}></img>
                <img className='img-bed' alt='' src={bed4}></img>
            </div>
            <img className='divan' alt='' src={screen4}></img>
        </>
    )
}
export default Divanchik