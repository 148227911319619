import price from "../../../assets/images/marketplace/price-card.png";
import approach from "../../../assets/images/marketplace/approach-card.png";
import team from "../../../assets/images/marketplace/team-card.png";
import left from "../../../assets/images/marketplace/pros-left-bg.webp";
import leftMobile from "../../../assets/images/marketplace/pros-left-bg-mobile.webp";
import right from "../../../assets/images/marketplace/pros-right-bg.webp";
import { DotsIcon, RhombShape } from "../../../assets/icon/marketplaceIcons";
import "./MarketPros.css";
import { useEffect } from "react";

const cards = [
  {
    title: `Привлекательная цена`,
    text: "Вы заплатите сумму, равную зарплате одного сотрудника, а получите услуги целой команды профессионалов.",
    img: price,
  },
  {
    title: `Индивидуальный подход`,
    text: "Работая с клиентом, мы прислушиваемся к его пожеланиям и предлагаем решения, которые и актуальны на рынке, и подходят конкретному заказчику.",
    img: approach,
  },
  {
    title: `Опытная команда`,
    text: "Помимо продвижения компаний на маркетплейсах, мы осуществляем full-service для онлайн-магазинов, а потому знаем все тонкости ведения бизнеса в интернете.",
    img: team,
  },
];

const ID = 'market-pros'

function MarketPros({ onStartPopupOpen }) {
  useEffect(() => { // Add this useEffect hook
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${ID}`)) {
      const element = document.getElementById(ID);
      if (element) {
        // Scroll the element into view
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, []);

  return (
    <div className="market-pros" id={ID}>
      <p className="market-pros__title">Преимущества</p>
      <div className="market-pros__cards-box">
        <ul className="market-pros__cards-list">
          {cards.map((item, i) => (
            <li
              className="market-pros__card-item"
              key={`market-pros-card-${i}`}
            >
              <p className="market-pros__card-title">{item.title}</p>
              <p className="market-pros__card-text">{item.text}</p>
              <img className="market-pros__card-img" src={item.img} alt="" />
              <RhombShape
                mainClassName="market-pros__card-rhomb market-pros__card-rhomb_type_left"
                fillClassName="market-pros__card-rhomb-fill"
              />
              <RhombShape
                mainClassName="market-pros__card-rhomb market-pros__card-rhomb_type_right"
                fillClassName="market-pros__card-rhomb-fill"
              />
            </li>
          ))}
        </ul>
        <img
          className="market-pros__rhomb-bg market-pros__rhomb-bg_type_left"
          src={left}
          alt=""
        />
        <img
          className="market-pros__rhomb-bg market-pros__rhomb-bg_type_mobile"
          src={leftMobile}
          alt=""
        />
        <img
          className="market-pros__rhomb-bg market-pros__rhomb-bg_type_right"
          src={right}
          alt=""
        />
      </div>
      <button
        className="market-pros__btn"
        type="button"
        onClick={onStartPopupOpen}
      >
        <DotsIcon
          mainClassName="market-pros__dots-icon"
          fillClassName="market-pros__dots-icon-fill"
        />
        <p className="market-pros__btn-text">Получить пользу</p>
      </button>
    </div>
  );
}

export default MarketPros;
