import './MainHowItWorks.css';
import MainHowItWorksPicDesktop from '../../assets/images/howBg.png';

import card1 from '../../assets/images/howCreateBg.png'
import card2 from '../../assets/images/howDesignBg.png'
import card3 from '../../assets/images/howFullingBg.png'
import card4 from '../../assets/images/howDirectBg.png'

import card1Mobile from '../../assets/images/howCreateBgMobile.png'
import card2Mobile from '../../assets/images/howDesignBgMobile.png'
import card3Mobile from '../../assets/images/howFullingBgMobile.png'
import card4Mobile from '../../assets/images/howDirectBgMobile.png'

import { SuiteData } from '../../assets/constants/suite';
import SuiteCard from '../AI-Suite/AI-Suite-Card/AI-Suite-Card';
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";

import PopupInput from '../PopupInput/PopupInput';
import { useEffect, useRef, useState } from 'react';
import { LK_LINK, LK_LINK_AUTH, LK_LINK_SIGNUP } from '../../assets/utils/constants';
import mainApi from '../../assets/api/MainApi';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import { sendMetriс } from '../../assets/utils/utils';


const HOW_WORKS_STEPS = [
  {
    title: `Создание`,
    discription: `Мы создадим онлайн-магазин, которому
       не будет равных! Мы примем во внимание
       каждое ваше пожелание и предложим
       лучшие решения.`,
    bg: card1,
    bgMobile: card1Mobile
  },
  {
    title: `Дизайн`,
    discription: `Главное преимущество дизайна — его адаптивность: онлайн-магазин будет одинаково удобным и красивым как с десктопной, так и с мобильной версии.`,
    bg: card2,
    bgMobile: card2Mobile
  },
  {
    title: `Наполнение`,
    discription: `Гибкая товарная система, разнообразие категорий, интеграция товаров из 1C, заполнение товаров — все это (и даже больше!) вы найдете в личном кабинете.`,
    bg: card3,
    bgMobile: card3Mobile
  },
  {
    title: `Управление`,
    discription: `Как управлять? Делегируйте задачи, подключив менеджеров, получайте уведомления о заказах
        в Telegram, добавьте онлайн-магазину уникальности
        с помощью собственного домена и самое приятное — отслеживайте рост вашего бизнеса.`,
    bg: card4,
    bgMobile: card4Mobile
  },
]

const ID = 'main-how-it-works'

const MainHowItWorks = () => {
  const [form, setForm] = useState({ phone: "" });
  const [formValid, setFormValid] = useState({ phone: {} });



  function handleClose() {

    setForm({ phone: "" });
    setFormValid({ phone: {} });
  }

  function handleChange(e) {
    const input = e.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case "phone":
        let inputValue = value.replace(/\D/g, "");
        let formattedInputValue = "";
        if (!inputValue) {
          setForm({ phone: "" });
          setFormValid((prevValue) => ({
            ...prevValue,
            phone: {
              errorMessage: "Можно вводить только цифры",
              validState: false,
            },
          }));
        } else {
          if (["7", "8", "9"].indexOf(inputValue[0]) > -1) {
            setFormValid((prevValue) => ({
              ...prevValue,
              phone: {
                errorMessage: "",
                validState: false,
              },
            }));
            if (inputValue[0] === "9") inputValue = "7" + inputValue;

            let firstSimbols = inputValue[0] === "8" ? "8" : "+7";
            formattedInputValue = firstSimbols + " ";

            if (inputValue.length > 1) {
              formattedInputValue += "(" + inputValue.substring(1, 4);
            }
            if (inputValue.length >= 5) {
              formattedInputValue += ") " + inputValue.substring(4, 7);
            }
            if (inputValue.length >= 8) {
              formattedInputValue += "-" + inputValue.substring(7, 9);
            }
            if (inputValue.length >= 10) {
              formattedInputValue += "-" + inputValue.substring(9, 11);
            }
            if (inputValue.length >= 11) {
              setFormValid((prevValue) => ({
                ...prevValue,
                phone: {
                  errorMessage: "",
                  validState: true,
                },
              }));
            } else {
              setFormValid((prevValue) => ({
                ...prevValue,
                phone: {
                  errorMessage: "",
                  validState: false,
                },
              }));
            }
          } else {
            formattedInputValue = "+" + inputValue.substring(0, 16);
            if (inputValue.length >= 11) {
              setFormValid((prevValue) => ({
                ...prevValue,
                phone: {
                  errorMessage: "",
                  validState: true,
                },
              }));
            } else {
              setFormValid((prevValue) => ({
                ...prevValue,
                phone: {
                  errorMessage: "",
                  validState: false,
                },
              }));
            }
          }

          setForm((prevValue) => ({
            ...prevValue,
            phone: formattedInputValue,
          }));
        }
        break;

      default:
        setForm((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;
    }
  }

  function handlePhoneDelete(e) {
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, "").length === 1) {
      setForm((prevValue) => ({
        ...prevValue,
        phone: "",
      }));
    }
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, "").length < 11) {
      setFormValid((prevValue) => ({
        ...prevValue,
        phone: {
          errorMessage: "",
          validState: false,
        },
      }));
    }
  }
const [isPreloaderVisible, setIsPreloaderVisible] = useState(false)
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsPreloaderVisible(true);

    mainApi
      .sendPhone({ phone: form.phone.replace(/\D/g, "") })
      .then((res) => {
        console.log(res.data);
        sendMetriс('reachGoal', 'LEAD_FORM_IM')
        window.location.href = `${LK_LINK}/${LK_LINK_AUTH}/${LK_LINK_SIGNUP}?phone=${form.phone.replace(
          /\D/g,
          ""
        )}`;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloaderVisible(false);

      });
  };



  const inputRef = useRef(null)

  useEffect(() => { // Add this useEffect hook
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${ID}`)) {
      const element = document.getElementById(ID);
      if (element) {
        // Scroll the element into view
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, []);

  return (
    <div className='main-how-it-works-box' id={ID}>
      <div className='main-how-it-works'>

        <div className='main-how-it-works__box'>
          <div className='main-how-it-works__box-texts'>
            <h1 className='main-how-it-works__box-texts-heading'>
              Как это работает?
            </h1>
            <div className='main-how-it-works__cards'>
              {HOW_WORKS_STEPS.map((item, i) => {
                return (
                  <> <div style={{ gridArea: `card${i + 1}` }} className={`main-how-it-works__card main-how-it-works__card-${i + 1}`}>
                    <p className='main-how-it-works__card__title'>
                      {item.title}
                    </p>
                    <p className='main-how-it-works__card__subtitle'>{item.discription}</p>
                    {i === 0 ?

                      <div
                        className="small-form-popup__container"
                        onClick={(e) => e.stopPropagation()}
                      >

                        <form className="small-form-popup__form" onSubmit={handleSubmit}>
                          <PopupInput
                            label={"Номер телефона"}
                            value={form.phone}
                            onChange={handleChange}
                            name={"phone"}
                            inputMode="tel"
                            error={formValid.phone}
                            onKeyDown={handlePhoneDelete}
                            refObj={inputRef}
                          />
                          <button
                            className={`small-form-popup__sbm-btn ${!formValid.phone.validState
                              ? "small-form-popup__sbm-btn_disabled"
                              : ""
                              }`}
                            type="submit"
                          >
                            {!isPreloaderVisible ? 'Начать' : <MiniPreloader />}
                          </button>
                        </form>
                      </div>

                      : null
                    }
                    {HOW_WORKS_STEPS.length === i + 1 ?
                      <>
                        <div className='suite__box-down_type_desktop'>
                          {SuiteData.map((card, idx) => {
                            // console.log(card.id)
                            return (
                              <SuiteCard key={card.id} card={card} idx={idx} />
                            )
                          })
                          }
                        </div>
                        <div className='suite__box-down_type_mobile'>
                          <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={8}
                            loop={false}
                            pagination={{
                              clickable: true,
                            }}
                            mousewheel={{
                              forceToAxis: true,
                            }}
                            modules={[Mousewheel, Pagination]}
                            preventInteractionOnTransition={true}
                            className={`suite__box-down-swiper`}
                            style={{ paddingBottom: '36px', position: 'absolute', left: '0', paddingRight: '16px', boxSizing: 'border-box', overflow: 'initial', height: 'unset', marginLeft: '10px', marginBottom: '12px' }}
                          >
                            {SuiteData.map((card, idx) => {
                              return (
                                <SwiperSlide style={{ width: '100%', maxWidth: '264px' }} className='suite__box-down-swiper-slide' key={card.id}>
                                  <SuiteCard card={card} />
                                </SwiperSlide>
                              )
                            })
                            }
                          </Swiper>
                        </div>
                      </>
                      : null}
                    <img className='main-how-it-works__card__img' src={item.bg}></img>
                    <img className='main-how-it-works__card__img_mobile' src={item.bgMobile}></img>
                  </div>
                    <div className={`main-how-it-works__card__arrow main-how-it-works__card__arrow-${i}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M11 1C11 0.447715 10.5523 2.41412e-08 10 0C9.44772 -2.41412e-08 9 0.447715 9 1L11 1ZM9.29289 19.7071C9.68342 20.0976 10.3166 20.0976 10.7071 19.7071L17.0711 13.3431C17.4616 12.9526 17.4616 12.3195 17.0711 11.9289C16.6805 11.5384 16.0474 11.5384 15.6569 11.9289L10 17.5858L4.34315 11.9289C3.95262 11.5384 3.31946 11.5384 2.92893 11.9289C2.53841 12.3195 2.53841 12.9526 2.92893 13.3431L9.29289 19.7071ZM9 1L9 19L11 19L11 1L9 1Z" fill="white" />
                      </svg>
                    </div>
                    <div className={`main-how-it-works__card__arrow main-how-it-works__card__arrow-bg-${i}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="99" height="29" viewBox="0 0 99 29" fill="none">
                        <path d="M49.5 -29L99 0L49.5 29L0 0L49.5 -29Z" className={`arrow-bg-${i + 1}`} />
                      </svg>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          {/*<img className='main-how-it-works__box-main-image main-how-it-works__box-main-image_type_tablet' src={main-how-it-worksPicTablet} alt='' />*/}
          {/* <img className='main-how-it-works__box-main-image main-how-it-works__box-main-image_type_mob' src={main-how-it-worksPicMob} alt='' /> */}
          <img className='main-how-it-works__box-main-image main-how-it-works__box-main-image_type_desktop' src={MainHowItWorksPicDesktop} alt='' />
        </div>
      </div>
    </div>
  )
}

export default MainHowItWorks;