import screen from '../../../assets/img/Portfolio/beyou/Screen-1.webp';
import screen1 from '../../../assets/img/Portfolio/beyou/screen-1.2.webp';
import screen2 from '../../../assets/img/Portfolio/beyou/Screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/beyou/Screen-3.webp';
import screen4 from '../../../assets/img/Portfolio/beyou/Screen-4.webp';
import bg1 from '../../../assets/img/Portfolio/beyou/bg-1.webp';
import bg2 from '../../../assets/img/Portfolio/beyou/bg-2.webp';
import slide1 from '../../../assets/img/Portfolio/beyou/slide-1.webp';
import slide2 from '../../../assets/img/Portfolio/beyou/slide-2.webp';
import slide3 from '../../../assets/img/Portfolio/beyou/slide-3.webp';
import slide4 from '../../../assets/img/Portfolio/beyou/slide-4.webp';

import './BeYou.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";

const BeYou = () => {
    return (
        <>
            <img className='screen-first-part' alt='' src={screen}></img>
            <img className='screen-second-part' alt='' src={screen1}></img>
            <div className='position-block__bg'>
                <img className='bg-1' alt='' src={bg1}></img>
            </div>
            <img className='screen screen_position' alt='' src={screen2}></img>
            <div className='position-block__slider'>
                <img className='img' src={slide1} alt=''></img>
                <img className='img' src={slide2} alt=''></img>
                <img className='img' src={slide3} alt=''></img>
                <img className='img' src={slide4} alt=''></img>
            </div>
            <img className='screen' alt='' src={screen3}></img>
            <img className='bg-2' alt='' src={bg2}></img>
            <img className='screen' alt='' src={screen4}></img>
        </>

    )
}
export default BeYou