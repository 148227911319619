import screen1 from '../../../assets/img/Portfolio/marker/Screen-1.webp';
import screen2 from '../../../assets/img/Portfolio/marker/Screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/marker/Screen-3.webp';
import screen4 from '../../../assets/img/Portfolio/marker/Screen-4.webp';
import screen5 from '../../../assets/img/Portfolio/marker/Screen-5.webp';
import screen6 from '../../../assets/img/Portfolio/marker/Screen-6.webp';
import bublik from '../../../assets/img/Portfolio/marker/bublik.webp';
import halfCircle from '../../../assets/img/Portfolio/marker/half-circle.webp';

import './Marker.css';

const Marker = () => {
    return (
        <>
            <img className='screen screen_marker' alt='' src={screen1}></img>
            <div className='screen marker__box_bublik-and-circle'>
                <img className='screen screen_marker' alt='' src={screen2}></img>
                <img className='marker__half-circle' alt='' src={halfCircle}></img>
                <img className='marker__bublik' alt='' src={bublik}></img>
            </div>
            <img className='screen screen_marker' alt='' src={screen3}></img>
            <div className='screen__box_marker-mobile'>
                <img className='screen screen_marker-mobile ' alt='' src={screen4}></img>
                <img className='screen screen_marker-mobile ' alt='' src={screen5}></img>
                <img className='screen screen_marker-mobile ' alt='' src={screen6}></img>
            </div>
            <div className='bg_bottom'></div>
        </>
    )
}
export default Marker