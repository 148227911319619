export const BottomLeftFigure = () => {
    return (
        <svg
            width={379}
            height={379}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity={0.2}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M189.5 0L0 189.5 189.5 379 379 189.5 189.5 0z"
                fill="#9DA4DD"
            />
        </svg>
    );
}

export const UpLeftFigure = () => {
    return (
        <svg
            width={335}
            height={335}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity={0.1}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M167.5 0L0 167.5 167.5 335 335 167.5 167.5 0z"
                fill="#9DA4DD"
            />
        </svg>
    );

}

export const UpRightFigure = () => {
    return (
        <svg
            width={627}
            height={627}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity={0.1}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M313.5 0L0 313.5 313.5 627 627 313.5 313.5 0z"
                fill="#9DA4DD"
            />
        </svg>
    );
}