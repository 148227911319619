import './HowBanner.css';
import banner from '../../../assets/images/how-it-works/banner.png'

function HowBanner() {
    return (
        <div className='how-banner'>
            
            <div className='how-banner__content'>
                <img className='how-banner__img' src={banner} alt=''></img>
                <p className='how-banner__title'>Как<br /> это работает?</p>
                <p className='how-banner__text'>Polza&nbsp;&mdash; это ваш путь к&nbsp;эффективному и&nbsp;прибыльному <span className='how-banner__text_no-wrap'>онлайн-бизнесу.</span></p>

               
            </div>

        </div>
    );
}

export default HowBanner