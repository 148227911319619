import './Footer.css';
import { HeaderLogoMob } from '../../assets/icons/icons';
import { Link, NavLink } from 'react-router-dom';
import ScrollToTop from '../../assets/hooks/scrollToTop';
import bg from '../../assets/images/FooterBg.png'
import { ARTICLES_LINK, INFO_LINK } from '../../assets/constants/constants';

function Footer() {

  return (
    <footer className="footer">
      <div className='footer__box'>
        <div className='footer__box__logo_small'> <HeaderLogoMob /></div>
        <div className='footer__box-nav-links'>
          <NavLink onClick={<ScrollToTop />} to='/' className={({ isActive }) =>
            (isActive ? "footer__box-nav-links-link_type_active" : "footer__box-nav-links-link_type_disabled")}>Главная</NavLink>
          <NavLink to='/how-it-works' className={({ isActive }) =>
            (isActive ? "footer__box-nav-links-link_type_active" : "footer__box-nav-links-link_type_disabled")}>Как это работает?</NavLink>
          <NavLink to='/tarif' className={({ isActive }) =>
            (isActive ? "footer__box-nav-links-link_type_active" : "footer__box-nav-links-link_type_disabled")}>Тариф</NavLink>
          <NavLink to='/marketplaces' className={({ isActive }) =>
            (isActive ? "footer__box-nav-links-link_type_active" : "footer__box-nav-links-link_type_disabled")}>Для маркетплейсов</NavLink>
          {/* <NavLink onClick={<ScrollToTop />} to='/projects' className={({ isActive }) =>
            (isActive ? "footer__box-nav-links-link_type_active" : "footer__box-nav-links-link_type_disabled")}>Проекты</NavLink> */}
          <a href={ARTICLES_LINK} className={'footer__box-nav-links-link_type_disabled'} target='_blank' rel='noreferrer'>
            Полезные статьи
          </a>
          <a href={INFO_LINK} className={'footer__box-nav-links-link_type_disabled'} target='_blank' rel='noreferrer'>
            База знаний
          </a>
          {/** <NavLink to='/faq' className={({ isActive }) =>
            (isActive ? "footer__box-nav-links-link_type_active" : "footer__box-nav-links-link_type_disabled")}>FAQ</NavLink>*/}
          <span className='footer__box-authorities'>Общество с ограниченной ответственностью "СЕЛЛ1" (ООО "СЕЛЛ1"), ИНН:  9714016972, КПП:  771401001, ОГРН:  1237700539405, ОКПО:  55414770</span>
        </div>
        <span className='footer__box-authorities'>Ⓒ Все права защищены. Polza – 2024</span>
      </div>
      <img src={bg} className='numbers__rhomb' alt=''></img>
    </footer>
  );
}

export default Footer;