import screen1 from '../../../assets/img/Portfolio/ArtMaster/screen-1.webp';
import screen2 from '../../../assets/img/Portfolio/ArtMaster/screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/ArtMaster/screen-3.webp';
import grid1 from '../../../assets/img/Portfolio/ArtMaster/grid-1.webp';
import grid2 from '../../../assets/img/Portfolio/ArtMaster/grid-2.webp';
import grid3 from '../../../assets/img/Portfolio/ArtMaster/grid-3.webp';
import grid4 from '../../../assets/img/Portfolio/ArtMaster/grid-4.webp';
import review1 from '../../../assets/img/Portfolio/ArtMaster/review-1.webp';
import review2 from '../../../assets/img/Portfolio/ArtMaster/review-2.webp';
import review3 from '../../../assets/img/Portfolio/ArtMaster/review-3.webp';

import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from 'swiper/modules';
import "swiper/css";

import './ArtMaster.css';

const ArtMaster = () => {
    return (
        <>
            <img className='screen' alt='' src={screen1}></img>
            <img className='screen' alt='' src={screen2}></img>
            <img className='screen' alt='' src={screen3}></img>
            <Swiper
              slidesPerView={'auto'}
              initialSlide={1.25}
              spaceBetween={10}
              centeredSlides={true}
              mousewheel={{
                forceToAxis: true,
              }}
              modules={[Mousewheel]}
              preventInteractionOnTransition={true}
              className='artmaster-swiper'
            >
                <SwiperSlide className='artmaster-slide' >
                <img className='artmaster-slide' alt='' src={review1}></img>
                </SwiperSlide>
                <SwiperSlide className='artmaster-slide' >
                <img className='artmaster-slide' alt='' src={review2}></img>
                </SwiperSlide>
                <SwiperSlide className='artmaster-slide' >
                <img className='artmaster-slide' alt='' src={review3}></img>
                </SwiperSlide>
            </Swiper>
            <div className='review-mobile'>
                <img className='artmaster-slide' alt='' src={review1}></img>
                <img className='artmaster-slide' alt='' src={review2}></img>
                <img className='artmaster-slide' alt='' src={review3}></img>
            </div>
            <div className='grid-artmaster'>
                <img className='img-ceiling' alt='' src={grid1}></img>
                <img className='img-ceiling' alt='' src={grid3}></img>
                <img className='img-ceiling' alt='' src={grid4}></img>
                <img className='img-ceiling' alt='' src={grid2}></img>
            </div>
        </>
    )
}
export default ArtMaster