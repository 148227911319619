import './MainBlockTemplate.css';

import { PROJECTS_GROUP } from '../../../assets/utils/constants';
import CardTemplate from '../../CardTemplate/CardTemplate';
import bg from '../../../assets/images/ProjectsMainBg.png';
import { useEffect } from 'react';

function MainBlockTemplate({ type, number, numberTitle, mainTitle, image, discription, onFeedbackOpen, id }) {

    useEffect(() => { // Add this useEffect hook
        if(!id) return
        // Check if the URL contains '#main-intro'
        if (window.location.href.includes(`#${id}`)) {
            const element = document.getElementById(id);
            if (element) {
                // Scroll the element into view
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, []);

    return (
        <div className='main' id={id}>
            <div className={`main__${type} main__number-side`}>
            
                <div className='main__block'>
                    <p className='service__title_small'>{mainTitle}</p>
                    <div className='projects__cards'>
                        {PROJECTS_GROUP.map((item, i) => (
                            <CardTemplate key={i} company={item.id} bg={item.bg} logo={item.logo} title={item.name} />
                        ))}
                    </div>
                </div>
                <img className='main-projects__bg' src={bg}></img>
            </div>
        </div>
    )
}
export default MainBlockTemplate