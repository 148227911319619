import './Header.css';
import '../Header/HeaderPopup/HeaderPopup.css';
import {
  HedaerLogoDesktop, HeaderLogoMob, PhoneIcon, PhoneIconTablet,
  HeaderMenuMobOpenBtn, HeaderMenuMobCloseBtn, HeaderLogoTablet
} from '../../assets/icons/icons';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { CONTACT_PHONE, CONTACT_EMAIL, ARTICLES_LINK } from '../../assets/constants/constants';
import demoPurple from '../../assets/images/demo_purple.svg'
import { LK_LINK, LK_LINK_AUTH, LK_LINK_LOGIN } from '../../assets/utils/constants';
import HeaderPopup from './HeaderPopup/HeaderPopup';
import { sendMetriс } from '../../assets/utils/utils';

function Header({ onStartPopupOpen }) {

  const onMenuBtnHandler = () => { setHeaderPopupOpen(!isHeaderPopupOpen) }

  const [isHeaderPopupOpen, setHeaderPopupOpen] = useState(false)
  const { state } = useLocation();
  const location = useLocation()
  const isInsideModal = state?.backgroundLocation;
  const is_marketplace_location = location.pathname === "/marketplaces"


  return (
    <>
      {!isInsideModal ?
        <header className="header">
          <div className='header__box' onClick={(e) => e.stopPropagation()}>
            <button type='Button' className='header__box-menu-mob-open-btn' onClick={onMenuBtnHandler}>
              {isHeaderPopupOpen ? <HeaderMenuMobCloseBtn /> : <HeaderMenuMobOpenBtn />}
            </button>
            <Link className='header__box-logo header__box-logo_type_desktop' to='/'>
              <HedaerLogoDesktop />
            </Link>
            <Link className='header__box-logo header__box-logo_type_tablet' to='/'>
              <HeaderLogoTablet />
            </Link>
            <Link className='header__box-logo header__box-logo_type_mob' to='/'>
              <HeaderLogoMob />
            </Link>
            <div className='header__box-nav-links'>
              <NavLink to='/' className={({ isActive }) =>
                (isActive ? "header__box-nav-links-link_type_active" : "header__box-nav-links-link_type_disabled")}>
                Главная
              </NavLink>
              <NavLink to='/how-it-works' className={({ isActive }) =>
                (isActive ? "header__box-nav-links-link_type_active" : "header__box-nav-links-link_type_disabled")}>
                Как это работает?
              </NavLink>
              <NavLink to='/tarif' className={({ isActive }) =>
                (isActive ? "header__box-nav-links-link_type_active" : "header__box-nav-links-link_type_disabled")}>
                Тариф
              </NavLink>
              <NavLink to='/marketplaces' className={({ isActive }) =>
                (isActive ? "header__box-nav-links-link_type_active" : "header__box-nav-links-link_type_disabled")}>
                Для маркетплейсов
              </NavLink>
              <a href={ARTICLES_LINK} className={'header__box-nav-links-link_type_disabled'} target='_blank' rel='noreferrer'>
                Полезные статьи 
              </a>
              {/*<NavLink to='/faq' className={({ isActive }) =>
                (isActive ? "header__box-nav-links-link_type_active" : "header__box-nav-links-link_type_disabled")}>
                FAQ
                </NavLink>*/}
            </div>
            {/* {!is_marketplace_location ?
              <button className='header__box__demo-button' onClick={onStartPopupOpen}>
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Frame 1948754324">
                    <circle id="Ellipse 1" cx="5" cy="6" r="3" fill="#7E5EFF" />
                    <circle id="Ellipse 3" opacity="0.5" cx="5" cy="14" r="3" fill="#7E5EFF" />
                    <circle id="Ellipse 2" opacity="0.6" cx="13" cy="6" r="3" fill="#7E5EFF" />
                    <circle id="Ellipse 4" opacity="0.3" cx="13" cy="14" r="3" fill="#7E5EFF" />
                  </g>
                </svg>

                Бесплатный Демо
              </button>
              : <div className='header__box__demo-button-placeholder'></div>} */}
            <div className='header__box-contacts'>
              <a className='header__box-contacts-box' href={`tel:${CONTACT_PHONE.value}`}>
                <div className='header__box-contacts-box-icon_type_desktop'><PhoneIcon /></div>
                <div className='header__box-contacts-box-icon_type_tablet'><PhoneIconTablet /></div>
                <span className='header__box-contacts-box-number'>{CONTACT_PHONE.formated_value}</span>
              </a>
              {/* <a onClick={() => sendMetriс('reachGoal', 'CLICK_EMAIL')}
                className='header__box-contacts-box' href={`mailto:${CONTACT_EMAIL}`}>
                <div className='header__box-contacts-box-icon_type_desktop'><EmailIcon /></div>
                <div className='header__box-contacts-box-icon_type_tablet'><EmailIconTablet /></div>
                <span className='header__box-contacts-box-email'>{CONTACT_EMAIL}</span>
              </a>*/}
              <a className='header__box__enter-button' href={`${LK_LINK}/${LK_LINK_AUTH}/${LK_LINK_LOGIN}`} onClick={() => {
                sendMetriс('reachGoal', 'LANDING_LOGIN_BTN_CLICK')
              }}>
                Войти
              </a>
            </div>
          </div>
        </header>
        : null}
      <HeaderPopup
        onStartPopupOpen={onStartPopupOpen}
        isHeaderPopupOpen={isHeaderPopupOpen}
        setHeaderPopupOpen={setHeaderPopupOpen}
      />
    </>
  )
}

export default Header;