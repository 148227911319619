import './Main.css';
import { SERVICE__DISCRIPTION_SEO } from '../../assets/utils/constants';
import MainBlockTemplate from './MainBlockTemplate/MainBlockTemplate';


import Intro from '../Intro/Intro';

import ScrollToTop from '../../assets/hooks/scrollToTop';
import { Helmet } from 'react-helmet';
import Decision from '../Decision/Decision';
import ServiceTemplate from './ServiceTemplate/ServiceTemplate';
import Integration from '../Integration/Integration';
import MainHowItWorks from '../MainHowItWorks/MainHowItWorks';
import { useState } from 'react';
import BusinessPlank from './BusinessPlank/BusinessPlank';
import RatePlank from './RatePlank/RatePlank';

function Main({ onFeedbackOpen, onStartPopupOpen, isStartPopupOpen }) {
  const [isPlankOpen, setIsPlankOpen] = useState({
    business: true,
    rate: true,
  })

  function closePlank() {
    setIsPlankOpen((prevVal) => ({ ...prevVal, business: false }))
  }

  return (
    <div className="main">
      <Helmet>
        <title>Polza - Главная</title>
      </Helmet>
      <ScrollToTop />
      <Intro isPlankOpen={isPlankOpen.business} {...{ onStartPopupOpen }} />
      <BusinessPlank isOpen={isPlankOpen.business} onClose={closePlank} />
      <Decision isPlankOpen={isPlankOpen.business} isStartPopupOpen={isStartPopupOpen} {...{ onStartPopupOpen }} />
      <MainHowItWorks />
      <Integration />
      <RatePlank {...{ onStartPopupOpen }} />
      <ServiceTemplate id={`main-promotion`} title={'Продвижение'} discription={SERVICE__DISCRIPTION_SEO}  {...{ onStartPopupOpen }} />
      <MainBlockTemplate id={`main-portfolio`} number={'05'} mainTitle={'Интернет-магазины, которые приносят пользу'} />
    </div>
  );
};

export default Main;

