import TarifBanner from './TarifBanner/TarifBanner';
import './Tarif.css';
import TrarifCards from './TrarifCards/TrarifCards';
import ScrollToTop from '../../assets/hooks/scrollToTop';
import { Helmet } from 'react-helmet';

function Tarif({
  onStartPopupOpen,
}) {
  return (
    <div className='tarif-page'>
      <Helmet>
        <title>Polza - Тариф</title>
      </Helmet>
      <div className='tarif-page__box'>
        <ScrollToTop />
        <TarifBanner />
        <TrarifCards onStartPopupOpen={onStartPopupOpen} />
      </div>
    </div>
  );
}

export default Tarif