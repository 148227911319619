import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper/modules";
import {
  CurveArrowIcon,
  DotsIcon,
  MainArrowIcon,
  MobileArrowIcon,
  RhombShape,
} from "../../../assets/icon/marketplaceIcons";
import img from "../../../assets/images/marketplace/work-img.png";
import card1 from "../../../assets/images/marketplace/work-card1.png";
import card2 from "../../../assets/images/marketplace/work-card2.png";
import icon1 from "../../../assets/images/marketplace/work-icon1.png";
import icon2 from "../../../assets/images/marketplace/work-icon2.png";
import icon3 from "../../../assets/images/marketplace/work-icon3.png";
import "./MarketWork.css";
import "swiper/css";
import "swiper/css/pagination";
import { useEffect } from "react";

const firstCardTitle = `Вы только
начинаете свой путь
на маркетплейсах`;

const firstCardText = `Создание карточек товара,
обучение правилам работы
с отзывами и контентом.`;

const secondCardTitle = `Ваш бизнес
уже размещен
на маркетплейсах`;

const secondCardText = `Анализ карточек товара,
усиление их путем
SEO-оптимизации, обновления
контента и работы с отзывами.`;

const ID = 'market-work'
function MarketWork({ onStartPopupOpen }) {

  useEffect(() => { // Add this useEffect hook
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${ID}`)) {
      const element = document.getElementById(ID);
      if (element) {
        // Scroll the element into view
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, []);

  return (
    <div className="market-work" id={ID}>
      <p className="market-work__title">Как мы это сделаем?</p>

      <div className="market-work__cards-block">
        <div className="market-work__card market-work__card_type_first">
          <div className="market-work__card-text-block">
            <p className="market-work__card-title">{firstCardTitle}</p>
            <p className="market-work__card-text">{firstCardText}</p>
          </div>
          <img className="market-work__card-img" src={card1} alt="" />
          <CurveArrowIcon
            mainClassName="market-work__card-arrow"
            fillClassName="market-work__arrow-fill"
          />
        </div>

        <div className="market-work__main-card">
          <MainArrowIcon
            mainClassName="market-work__main-card-arrow"
            fillClassName="market-work__arrow-fill"
          />
          <MainArrowIcon
            mainClassName="market-work__main-card-arrow market-work__main-card-arrow_type_right"
            fillClassName="market-work__arrow-fill"
          />
          <MobileArrowIcon
            mainClassName="market-work__main-card-arrow market-work__main-card-arrow_type_mobile"
            fillClassName="market-work__arrow-fill"
          />
          <img className="market-work__main-card-img" src={img} alt="" />
          <p className="market-work__text">
            Наш менеджер проводит с&nbsp;вами первичное обсуждение текущей
            ситуации.
          </p>
        </div>

        <div className="market-work__card market-work__card_type_second">
          <div className="market-work__card-text-block">
            <p className="market-work__card-title">{secondCardTitle}</p>
            <p className="market-work__card-text">{secondCardText}</p>
          </div>
          <img className="market-work__card-img" src={card2} alt="" />
          <CurveArrowIcon
            mainClassName="market-work__card-arrow market-work__card-arrow_type_right"
            fillClassName="market-work__arrow-fill"
          />
        </div>

        <div className="market-work__card market-work__card_type_long">
          <div className="market-work__card-block">
            <img className="market-work__card-icon" src={icon1} alt="" />
            <p className="market-work__card-text">Детальный расчет акций.</p>
          </div>
          <div className="market-work__card-block">
            <img className="market-work__card-icon" src={icon2} alt="" />
            <p className="market-work__card-text">
              Внедрение внутреннего и внешнего продвижения карточек товара.
            </p>
          </div>
          <div className="market-work__card-block">
            <img className="market-work__card-icon" src={icon3} alt="" />
            <p className="market-work__card-text">
              Оценка и прогнозирование будущих результатов.
            </p>
          </div>
        </div>
      </div>

      <div className="market-work__cards-block market-work__cards-block_type_mobile">
        <div className="market-work__main-card">
          <MobileArrowIcon
            mainClassName="market-work__main-card-arrow market-work__main-card-arrow_type_mobile"
            fillClassName="market-work__arrow-fill"
          />
          <img className="market-work__main-card-img" src={img} alt="" />
          <p className="market-work__text">
            Наш менеджер проводит с&nbsp;вами первичное обсуждение текущей
            ситуации.
          </p>
        </div>

        <div className="market-work__swiper-container">
          <Swiper
            className="market-work__swiper"
            slidesPerView={"auto"}
            modules={[Mousewheel, Pagination]}
            spaceBetween={10}
            grabCursor={true}
            mousewheel={{ forceToAxis: true }}
            touchMoveStopPropagation={true}
            preventInteractionOnTransition={true}
            pagination={{ clickable: true }}
          >
            <SwiperSlide className="market-work__slide">
              <div className="market-work__card market-work__card_type_first">
                <div className="market-work__card-text-block">
                  <p className="market-work__card-title">{firstCardTitle}</p>
                  <p className="market-work__card-text">{firstCardText}</p>
                </div>
                <img className="market-work__card-img" src={card1} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide className="market-work__slide">
              <div className="market-work__card market-work__card_type_second">
                <div className="market-work__card-text-block">
                  <p className="market-work__card-title">{secondCardTitle}</p>
                  <p className="market-work__card-text">{secondCardText}</p>
                </div>
                <img className="market-work__card-img" src={card2} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
          <MobileArrowIcon
            mainClassName="market-work__main-card-arrow market-work__main-card-arrow_type_mobile"
            fillClassName="market-work__arrow-fill"
          />
        </div>

        <div className="market-work__card market-work__card_type_long">
          <div className="market-work__card-block">
            <img className="market-work__card-icon" src={icon1} alt="" />
            <p className="market-work__card-text">Детальный расчет акций.</p>
          </div>
          <div className="market-work__card-block">
            <img className="market-work__card-icon" src={icon2} alt="" />
            <p className="market-work__card-text">
              Внедрение внутреннего и внешнего продвижения карточек товара.
            </p>
          </div>
          <div className="market-work__card-block">
            <img className="market-work__card-icon" src={icon3} alt="" />
            <p className="market-work__card-text">
              Оценка и прогнозирование будущих результатов.
            </p>
          </div>
        </div>
      </div>

      <button
        className="market-work__btn"
        type="button"
        onClick={onStartPopupOpen}
      >
        <DotsIcon
          mainClassName="market-work__dots-icon"
          fillClassName="market-work__dots-icon-fill"
        />
        <p className="market-work__btn-text">Выйти на маркетплейсы</p>
      </button>

      <RhombShape
        mainClassName="market-work__rhomb market-work__rhomb_type_left"
        fillClassName="market-work__rhomb-fill"
      />
      <RhombShape
        mainClassName="market-work__rhomb market-work__rhomb_type_right"
        fillClassName="market-work__rhomb-fill"
      />
    </div>
  );
}

export default MarketWork;
