import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination } from "swiper/modules";
import { DotsIcon } from "../../../assets/icon/marketplaceIcons";
import card1 from "../../../assets/images/marketplace/profit-card1.png";
import card2 from "../../../assets/images/marketplace/profit-card2.png";
import card3 from "../../../assets/images/marketplace/profit-card3.png";
import card4 from "../../../assets/images/marketplace/profit-card4.png";
import "./MarketProfit.css";
import "swiper/css";
import "swiper/css/pagination";
import { useEffect } from "react";

const cards = [
  {
    text: `Увеличение скорости развития вашего бизнеса благодаря новым клиентам на маркетплейсах.`,
    img: card1,
  },
  {
    text: `Карточки товаров, которые будут выглядеть привлекательно
    для покупателей.`,
    img: card2,
  },
  {
    text: `Знание всех тонкостей общения «продавец —
    покупатель»
    на маркетплейсах.`,
    img: card3,
  },
  {
    text: `Понимание темпов
    развития вашего бизнеса
    в краткосрочной
    и долгосрочной перспективе.`,
    img: card4,
  },
];

const ID = 'market-profit'

function MarketProfit({ onStartPopupOpen }) {

  useEffect(() => { // Add this useEffect hook
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${ID}`)) {
      const element = document.getElementById(ID);
      if (element) {
        // Scroll the element into view
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, []);

  return (
    <div className="market-profit" id={ID}>
      <p className="market-profit__title">Что вы получите?</p>

      <ul className="market-profit__cards-list">
        {cards.map((item, i) => (
          <li
            className="market-profit__card-item"
            key={`market-profit-card-${i}`}
          >
            <p className="market-profit__card-text">{item.text}</p>
            <img className="market-profit__card-img" src={item.img} alt="" />
          </li>
        ))}
      </ul>

      <Swiper
        className="market-profit__swiper"
        slidesPerView={1.4}
        modules={[Mousewheel, Pagination]}
        spaceBetween={10}
        grabCursor={true}
        mousewheel={{ forceToAxis: true }}
        touchMoveStopPropagation={true}
        preventInteractionOnTransition={true}
        pagination={{ clickable: true }}
      >
        {cards.map((item, i) => (
          <SwiperSlide
            className="market-profit__slide"
            key={`market-profit-slide-${i}`}
          >
            <div className="market-profit__card-item">
              <p className="market-profit__card-text">{item.text}</p>
              <img className="market-profit__card-img" src={item.img} alt="" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <button
        className="market-profit__btn"
        type="button"
        onClick={onStartPopupOpen}
      >
        <DotsIcon
          mainClassName="market-profit__dots-icon"
          fillClassName="market-profit__dots-icon-fill"
        />
        <p className="market-profit__btn-text">Подать заявку</p>
      </button>
    </div>
  );
}

export default MarketProfit;
