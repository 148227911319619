import screen1 from '../../../assets/img/Portfolio/GeneticClinic/Screen-1.webp';
import screen2 from '../../../assets/img/Portfolio/GeneticClinic/Screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/GeneticClinic/Screen-3.webp';
import screen4 from '../../../assets/img/Portfolio/GeneticClinic/Screen-4.webp';
import screen5 from '../../../assets/img/Portfolio/GeneticClinic/Screen-5.webp';
import screen6 from '../../../assets/img/Portfolio/GeneticClinic/Screen-6.webp';
import screen7 from '../../../assets/img/Portfolio/GeneticClinic/Screen-7.webp';
import screen8 from '../../../assets/img/Portfolio/GeneticClinic/Screen-8.webp';
import screen9 from '../../../assets/img/Portfolio/GeneticClinic/Screen-9.webp';

import './GeneticClinic.css';

const GeneticClinic = () => {
    return (
        <>
            <img className='screen screen_no-border-radius' alt='' src={screen1}></img>
            <img className='screen screen_no-border-radius' alt='' src={screen2}></img>
            <img className='screen screen_no-border-radius' alt='' src={screen3}></img>
            <img className='screen screen_no-border-radius' alt='' src={screen4}></img>
            <img className='screen screen_no-border-radius' alt='' src={screen5}></img>
            <img className='screen screen_no-border-radius' alt='' src={screen6}></img>
            <img className='screen screen_no-border-radius' alt='' src={screen7}></img>
            <img className='screen screen_no-border-radius' alt='' src={screen8}></img>
            <img className='screen screen_no-border-radius' alt='' src={screen9}></img>


        </>
    )
}
export default GeneticClinic