import { CommerceIcon, DesignDevLogoIcon, DesignIcon, HostingIcon, MarketingIcon, MarketplaceIcon, ReDesignIcon, SeoIcon, SiteDevIcon, SmmIcon, StyleIcon, SupportIcon, VebIcon } from '../icon/mainIcon';
import divanchik_bg from '../../assets/img/Project/Dev/Divanchik/divanchik.webp';
import alclic_bg from '../../assets/img/Project/Dev/Alclic/alclic-bg.webp';
import bigbro_bg from '../../assets/img/Project/Dev/BigBro/bigbro-bg.webp';
import beyou_bg from '../../assets/img/Project/Dev/BeYou/beyou.webp';

import bigbro_baner from '../../assets/img/Portfolio/bigbro/bigbro.webp';
import alclic_baner from '../../assets/img/Portfolio/alclic/alclic-4.webp';
import divanchik_baner from '../../assets/img/Portfolio/divanchik/divanchik-1.webp';
import beyou_baner from '../../assets/img/Portfolio/beyou/beyou-3.webp';

import SBA_bg from '../../assets/img/Project/Design/SBA/SBA-bg.webp';
import kamnizdes_bg from '../../assets/img/Project/Design/Kamnizdes/kamnizdes-bg.webp';
import master_bg from '../../assets/img/Project/Design/ArtMaster/artmaster-bg.webp';
import gc_bg from '../../assets/img/Project/Design/GC/GC.svg';

import gc_baner from '../../assets/img/Portfolio/GeneticClinic/GC.webp';
import kamnizdes_baner from '../../assets/img/Project/Design/Kamnizdes/kamnizdes-baner.webp';
import sba_baner from '../../assets/img/Portfolio/SBA/sba.webp';
import master_baner from '../../assets/img/Portfolio/ArtMaster/artmaster.webp';

import epi_baner from '../../assets/img/Portfolio/dr.epi/epi-baner1.webp';
import epi_bg from '../../assets/img/Portfolio/dr.epi/epi.webp';
import boom_bg from '../../assets/img/Portfolio/BoomPhone/boom.webp';
import boom_baner from '../../assets/img/Portfolio/BoomPhone/boombaner.webp';
import boomsite_baner from '../../assets/img/Portfolio/BoomSite/boombaner.webp';
import boomsite_bg from '../../assets/img/Portfolio/BoomSite/boom.webp';
import brainspace_bg from '../../assets/img/Portfolio/BrainSpace/BrainSpace.webp';
import brainspace_baner from '../../assets/img/Portfolio/BrainSpace/baner.webp';

import kover_bg from '../../assets/img/Portfolio/kover/kover-bg.webp';
import gurme_bg from '../../assets/img/Portfolio/kosher/gurme-bg.webp';
import kover_baner from '../../assets/img/Portfolio/kover/kover-baner.webp';
import gurme_baner from '../../assets/img/Portfolio/kosher/gurme-baner.webp';
import marker_bg from '../../assets/img/Portfolio/marker/marker-bg.webp';
import marker_baner from '../../assets/img/Portfolio/marker/marker-baner.webp';

export const MAIN_URL = "https://app.polza.tech"
// export const MAIN_URL = "https://app-demo.polza.tech"

export const SERVICE__DISCRIPTION_DEV = [
    {
        title: 'Разработка</br> сайтов',
        text: 'Создание современного адаптивного сайта с&nbsp;нуля: разработка дизайна, программирование, тестирование и&nbsp;запуск сайта.',
        icon: <SiteDevIcon />,
    },
    {
        title: 'Редизайн</br> сайтов',
        text: 'Обновление внешнего вида сайта, улучшение его функциональности и&nbsp;конверсии, а&nbsp;также фокус на&nbsp;качестве будущего пользовательского опыта.',
        icon: <ReDesignIcon />,
    },
    {
        title: 'E-Commerce</br> решения',
        text: 'Набор инструментов для создания онлайн-магазина и&nbsp;управления им&nbsp;в&nbsp;зависимости от&nbsp;конкретных потребностей вашего бизнеса.',
        icon: <CommerceIcon />,
    },
]

export const SERVICE__DISCRIPTION_DESIGN = [
    {
        title: 'Разработка</br> логотипа',
        text: 'Создание уникального и&nbsp;запоминающегося логотипа, отражающего идентичность и&nbsp;ценности вашего бренда.',
        icon: <DesignDevLogoIcon />,
    },
    {
        title: 'Фирменный</br> стиль',
        text: 'Разработаем совокупность визуальных и&nbsp;графических элементов, которые повысят узнаваемость бренда и&nbsp;доверие клиентов к&nbsp;нему.',
        icon: <StyleIcon />,
    },
    {
        title: 'Дизайн сайтов</br> и презентаций',
        text: 'Создание оригинального продающего дизайна сайта и&nbsp;презентаций, ориентированного на&nbsp;целевую аудиторию, цели и&nbsp;ценности вашей компании.',
        icon: <DesignIcon />,
    },
]

export const SERVICE__DISCRIPTION_SEO = [
    {
        title: 'SEO',
        text: 'Оптимизация веб-сайта, улучшающая его видимость и&nbsp;ранжирование в&nbsp;поисковых системах.',
        icon: <SeoIcon />,
        transcription: 'Search Engine Optimization'
    },
    {
        title: 'SMM',
        text: 'Создание стратегий для продвижения вашего бренда в&nbsp;социальных сетях, а&nbsp;также налаживание интеграций с&nbsp;веб-сайтами.',
        icon: <SmmIcon />,
        transcription: 'Social Media Marketing'
    },
    {
        title: 'Маркетинг</br> и PR',
        text: 'Разработка индивидуальной маркетинговой стратегии, при помощи которой будут привлечены новые клиенты и&nbsp;увеличены продажи.',
        icon: <MarketingIcon />,
    },
    {
        title: `Торговые
        площадки`,
        text: `Размещение товаров или услуг
        вашего бренда на торговых площадках позволит кратно увеличить аудиторию.`,
        icon: <MarketplaceIcon />
    }
]

export const SERVICE__DISCRIPTION_SUPPORT = [
    {
        title: 'Хостинг',
        text: 'Предоставление места для хранения веб-сайта на&nbsp;сервере, имеющем постоянный доступ к&nbsp;сети Интернет.',
        icon: <HostingIcon />,
    },
    {
        title: 'Поддержка',
        text: 'Техническая поддержка веб-сайта для обеспечения его непрерывной работы и&nbsp;функциональности.',
        icon: <SupportIcon />,
    },
    {
        title: 'Веб-аналитика',
        text: 'Анализ посещаемости веб-сайта пользователями и&nbsp;стратегий их&nbsp;поведения, способствующий увеличению эффективности работы сайта.',
        icon: <VebIcon />,
    },
]

export const PROJECTS_DEVELOPMENT = [
    {
        id: "bigbro",
        name: "BIG BRO PRO",
        bg: bigbro_bg,
        text: [
            'Сайт',
            'Ребрендинг',
        ],
        baner: bigbro_baner,
        field: 'современные и стильные ивенты в Москве',
        field_discription: "Нами был создан сайт и проведён ребрендинг компании. Благодаря этому у BIGBROPRO появился обновлённый имидж, который цепляет аудиторию.",
    },
    {
        id: "alclic",
        name: "ALCLIC",
        bg: alclic_bg,
        text: [
            'Сайт',
            'Брендинг',
            'Разработка дизайна',
            'Логотип',
        ],
        baner: alclic_baner,
        field: 'компания, помогающая в получении алкогольной лицензии',
        field_discription: 'В данном кейсе мы разработали брендинг и дизайн для компании. Помимо этого, нами был создан узнаваемый логотип ALCLIC. Благодаря улучшению айдентики после проделанной работы у компании вырос спрос на услуги.'
    },
    {
        id: "divanchik",
        name: "Диванчик",
        bg: divanchik_bg,
        text: [
            'Сайт',
            'Ребрендинг',
            'Продвижение',
        ],
        baner: divanchik_baner,
        field: 'популярный в Сибири мебельный салон',
        field_discription: 'Для этой компании мы провели ребрендинг сайта и настроили маркетинговое продвижение, в результате чего каталог Диванчика стал гораздо удобнее, а аудитория нашего клиента быстро и просто узнаёт о нём.'
    },
    {
        id: "beyou",
        name: "BeYou",
        bg: beyou_bg,
        text: [
            'Сайт',
            'Айдентика бренда',
            'Логотип',
            'Продвижение',
        ],
        baner: beyou_baner,
        field: 'корейский бренд одежды с доставкой в Россию',
        field_discription: "Нами была разработана айдентика бренда и логотип, а также создан сайт. На данный момент благодаря этим этапам BeYou уже начал развиваться, однако в целях повышения эффективности клиент обратился к нам за маркетинговым продвижением, которое будет осуществлено в ближайшем будущем.",
    },
];

export const getProject = (tid) => PROJECTS_DEVELOPMENT.find(({ id }) => id === tid);

export const PROJECTS_DESIGN = [
    {
        id: "geneticclinic",
        name: "Genetica Clinic",
        bg: gc_bg,
        text: [
            'Брендинг',
            'Брендбук',
            'Логотип',
        ],
        baner: gc_baner,
        field: 'салон лазерной эпиляции премиум-сегмента',
        field_discription: "В данном кейсе мы разработали брендинг и брендбук, а также создали логотип для компании.  Это повысило не только узнаваемость бренда, но и доверие аудитории к нему. За счёт этого у Genetica Clinic выросло количество клиентов.",
    },
    {
        id: "sba",
        name: "SBA Club",
        bg: SBA_bg,
        text: [
            'Брендинг',
            'Брендбук',
            'Логотип',
        ],
        baner: sba_baner,
        field: 'Федерация бразильского джиу-джитсу',
        field_discription: 'В данном кейсе мы разработали брендинг и брендбук, а также создали логотип компании SBA. Как результат, клиент получил целостный образ бренда, чья философия понятна и близка целевой аудитории.'
    },
    {
        id: "kamnizdes",
        name: "Kamnizdes",
        bg: kamnizdes_bg,
        text: [
            'Сайт',
            'Продвижение',
        ],
        baner: kamnizdes_baner,
        field: 'продажа природного камня',
        field_discription: "Для компании мы создали сайт на платформе Tilda и настроили маркетинговое продвижение на Яндексе. Как результат, Kamnizdes смогли повысить доверие клиентов к бренду и увеличить продажи.",
    },
    {
        id: "artmaster",
        name: "Art Master.Pro",
        bg: master_bg,
        text: [
            'Сайт',
            'Логотип',
            'Продвижение',
        ],
        baner: master_baner,
        field: 'компания по установке натяжных потолоков',
        field_discription: "Работая с Art Master, мы создали рукописный сайт, а также разработали логотип компании. Это позволило повысить статус Art Master в интернет-пространстве, однако для получения максимального результата было принято решение о маркетинговом продвижении в ближайшем будущем.",
    },
];

export const getProjectDesign = (tid) => PROJECTS_DESIGN.find(({ id }) => id === tid);

export const PROJECTS_GROUP = [
    {
        id: "drepi",
        name: "DR. EPI",
        bg: epi_bg,
        text: [
            'Сайт',
            'Логотип',
            'Продвижение',
        ],
        baner: epi_baner,
        field: 'салон лазерной эпиляции эконом-сегмента',
        field_discription: "При работе с DR. EPI мы создали сайт, логотип и визуалы для бренда. После раскрытия полноценного образа компании DR. EPI удалось привлекать больше клиентов, чем прежде.",
    },
    {
        id: "boomphone",
        name: "Boom Telecom",
        bg: boom_bg,
        text: [
            'Приложение'
        ],
        baner: boom_baner,
        field: 'современный оператор сотовой связи',
        field_discription: "В данной части кейса о Boom Telecom — о созданном нами приложении для клиента. После появления приложения многие процессы автоматизировались и взаимодействие аудитории с компанией стало проще.",
    },
    {
        id: "boomsite",
        name: "Boom Telecom",
        bg: boomsite_bg,
        text: [
            'Сайт'
        ],
        baner: boomsite_baner,
        field: 'современный оператор сотовой связи',
        field_discription: "В данной части кейса о Boom Telecom — о созданном нами сайте компании. Сайт является полноценным ресурсом, к которому в дополнение было создано приложение. В результате компания стала максимально ориентирована на своего клиента (молодые люди), имея две платформы для взаимодействия с ним.",
    },
    {
        id: "brainspace",
        name: "Brain Space",
        bg: brainspace_bg,
        text: [
            'Сайт',
            'Брендинг',
            'Логотип',
        ],
        baner: brainspace_baner,
        field: 'сервис подбора психологов и психотерапевтов, онлайн-консультации',
        field_discription: "Для компании Brain Space мы разработали брендинг, а также создали сайт и логотип. По итогу был обретён свой фирменный стиль, мягкий и эстетичный, что так важно для данной сферы деятельности.",
    },
    {
        id: "geneticclinic",
        name: "Genetica Clinic",
        bg: gc_bg,
        text: [
            'Брендинг',
            'Брендбук',
            'Логотип',
        ],
        baner: gc_baner,
        field: 'салон лазерной эпиляции премиум-сегмента',
        field_discription: "В данном кейсе мы разработали брендинг и брендбук, а также создали логотип для компании.  Это повысило не только узнаваемость бренда, но и доверие аудитории к нему. За счёт этого у Genetica Clinic выросло количество клиентов.",
    },
    {
        id: "sba",
        name: "SBA Club",
        bg: SBA_bg,
        text: [
            'Брендинг',
            'Брендбук',
            'Логотип',
        ],
        baner: sba_baner,
        field: 'Федерация бразильского джиу-джитсу',
        field_discription: 'В данном кейсе мы разработали брендинг и брендбук, а также создали логотип компании SBA. Как результат, клиент получил целостный образ бренда, чья философия понятна и близка целевой аудитории.'
    },
    {
        id: "kamnizdes",
        name: "Kamnizdes",
        bg: kamnizdes_bg,
        text: [
            'Сайт',
            'Продвижение',
        ],
        baner: kamnizdes_baner,
        field: 'продажа природного камня',
        field_discription: "Для компании мы создали сайт на платформе Tilda и настроили маркетинговое продвижение на Яндексе. Как результат, Kamnizdes смогли повысить доверие клиентов к бренду и увеличить продажи.",
    },
    {
        id: "artmaster",
        name: "Art Master.Pro",
        bg: master_bg,
        text: [
            'Сайт',
            'Логотип',
            'Продвижение',
        ],
        baner: master_baner,
        field: 'компания по установке натяжных потолоков',
        field_discription: "Работая с Art Master, мы создали рукописный сайт, а также разработали логотип компании. Это позволило повысить статус Art Master в интернет-пространстве, однако для получения максимального результата было принято решение о маркетинговом продвижении в ближайшем будущем.",
    },
    {
        id: "bigbro",
        name: "BIG BRO PRO",
        bg: bigbro_bg,
        text: [
            'Сайт',
            'Ребрендинг',
        ],
        baner: bigbro_baner,
        field: 'современные и стильные ивенты в Москве',
        field_discription: "Нами был создан сайт и проведён ребрендинг компании. Благодаря этому у BIGBROPRO появился обновлённый имидж, который цепляет аудиторию.",
    },
    {
        id: "alclic",
        name: "ALCLIC",
        bg: alclic_bg,
        text: [
            'Сайт',
            'Брендинг',
            'Разработка дизайна',
            'Логотип',
        ],
        baner: alclic_baner,
        field: 'компания, помогающая в получении алкогольной лицензии',
        field_discription: 'В данном кейсе мы разработали брендинг и дизайн для компании. Помимо этого, нами был создан узнаваемый логотип ALCLIC. Благодаря улучшению айдентики после проделанной работы у компании вырос спрос на услуги.'
    },
    {
        id: "divanchik",
        name: "Диванчик",
        bg: divanchik_bg,
        text: [
            'Сайт',
            'Ребрендинг',
            'Продвижение',
        ],
        baner: divanchik_baner,
        field: 'популярный в Сибири мебельный салон',
        field_discription: 'Для этой компании мы провели ребрендинг сайта и настроили маркетинговое продвижение, в результате чего каталог Диванчика стал гораздо удобнее, а аудитория нашего клиента быстро и просто узнаёт о нём.'
    },
    {
        id: "beyou",
        name: "BeYou",
        bg: beyou_bg,
        text: [
            'Сайт',
            'Айдентика бренда',
            'Логотип',
            'Продвижение',
        ],
        baner: beyou_baner,
        field: 'корейский бренд одежды с доставкой в Россию',
        field_discription: "Нами была разработана айдентика бренда и логотип, а также создан сайт. На данный момент благодаря этим этапам BeYou уже начал развиваться, однако в целях повышения эффективности клиент обратился к нам за маркетинговым продвижением, которое будет осуществлено в ближайшем будущем.",
    },
    {
        id: "kosher",
        name: "Кошер Гурмэ",
        bg: gurme_bg,
        text: [
            'Промо-материал',
            'Брендинг',
        ],
        baner: gurme_baner,
        field: 'супермаркет кошерных продуктов',
        field_discription: "В данном кейсе мы разработали онлайн-магазин на базе платформы Polza, повысивший популярность компании на рынке. Количество онлайн-заказов сравнялось с оффлайн-покупками, увеличив тем самым прибыль компании.",
    },
    {
        id: "kover",
        name: "Ваш ковёр",
        bg: kover_bg,
        text: [
            'Промо-материал',
            'Брендинг',
        ],
        baner: kover_baner,
        field: 'ковры премиум-класса',
        field_discription: "Для компании наша команда разработала онлайн-магазин на базе платформы Polza. В результате у магазина увеличилась известность среди целевой аудитории, готовой приобретать ковры премиального качества, благодаря чему продажи выросли.",
    },
    {
        id: "marker",
        name: "Маркер",
        bg: marker_bg,
        text: [
            'Промо-материал',
            'Брендинг',
        ],
        baner: marker_baner,
        field: 'магазин канцелярских товаров',
        field_discription: "Для компании Маркер нами был создан онлайн-магазин на базе платформы Polza, который выделил нашего клиента среди конкурентов и поднял спрос на товары из каталога благодаря уникальным предложениям.",
    },
];

export const getProjectGroup = (tid) => PROJECTS_GROUP.find(({ id }) => id === tid);


export const DEFAULT_SERVICE_TYPES = [
    {
        title: 'SMM',
        type: 'smm',
    },
    {
        title: 'Редизайн сайта',
        type: 'redesign',
    },
    {
        title: 'Создание сайта',
        type: 'website_create',
    },
    {
        title: 'Хостинг',
        type: 'host',
    },
    {
        title: 'Поддержка',
        type: 'support',
    },
    {
        title: 'Аналитика',
        type: 'analytics',
    },
    {
        title: 'SEO',
        type: 'seo',
    },
]


export const LK_LINK = 'https://lk.polza.tech'
export const LK_LINK_AUTH = 'auth'
export const LK_LINK_SIGNUP = 'signup'
export const LK_LINK_LOGIN = 'login'