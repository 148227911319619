import { Helmet } from "react-helmet";
import ScrollToTop from "../../assets/hooks/scrollToTop";
import MarketForm from "./MarketForm/MarketForm";
import MarketHeading from "./MarketHeading/MarketHeading";
import MarketProfit from "./MarketProfit/MarketProfit";
import MarketPros from "./MarketPros/MarketPros";
import MarketWork from "./MarketWork/MarketWork";
import "./Marketplace.css";

function Marketplace({ onStartPopupOpen }) {
  return (
    <section className="marketplace">
      <Helmet>
        <title>Polza - Маркетплейсы</title>
      </Helmet>
      <ScrollToTop />
      <MarketHeading {...{ onStartPopupOpen }} />
      <MarketPros {...{ onStartPopupOpen }} />
      <MarketProfit {...{ onStartPopupOpen }} />
      <MarketWork {...{ onStartPopupOpen }} />
      <MarketForm />
    </section>
  );
}

export default Marketplace;
