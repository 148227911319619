import screen1 from '../../../assets/img/Portfolio/SBA/screen-1.webp';
import screen2 from '../../../assets/img/Portfolio/SBA/screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/SBA/screen-3.webp';
import screen4 from '../../../assets/img/Portfolio/SBA/screen-3.1.webp';
import screen5 from '../../../assets/img/Portfolio/SBA/screen-5.webp';
import screen6 from '../../../assets/img/Portfolio/SBA/screen-6.webp';

import logo from '../../../assets/img/Portfolio/SBA/logo.webp';
import './SBA.css';

const SBA = () => {
    return (
        <>
            <img className='screen-logo' alt='' src={logo}></img>
            <img className='screen-sba' alt='' src={screen1}></img>
            <img className='screen-sba' alt='' src={screen2}></img>
            <img className='screen__sba-hoodie' alt='' src={screen3}></img>
            <img className='screen__sba-phone' alt='' src={screen4}></img>
            <img className='screen__sba-fighters' alt='' src={screen5}></img>

            <div className='screen__sba-fighters-desktop'></div>

            <img className='screen-sba' alt='' src={screen6}></img>


        </>
    )
}
export default SBA