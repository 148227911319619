import PlankContainer from "../PlankContainer/PlankContainer";
import img from "../../../assets/images/main/business-plank-icon.png";
import "./BusinessPlank.css";
import { PlankArrowIcon, LightningIcon } from "../../../assets/icon/mainIcon";
import { useEffect } from "react";

const ID = 'main-business-plank'

function BusinessPlank({ isOpen, onClose }) {
  
  useEffect(() => { // Add this useEffect hook
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${ID}`)) {
        const element = document.getElementById(ID);
        if (element) {
            // Scroll the element into view
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }
}, []);
  return (
    <PlankContainer type="business" {...{ isOpen, onClose }}>
      <div className="business-plank" id={ID}>
        <div className="business-plank__title-block">
          <img className="business-plank__img" src={img} alt="" />
          <p className="business-plank__title">
            Ваш бизнес
            <br />
            не в сети?
          </p>
        </div>
        <div className="business-plank__text-block">
          <p className="business-plank__text business-plank__text_type_top-left">
            Сложности с онлайн–
            <br />
            продажами?
          </p>
          <p className="business-plank__text business-plank__text_type_top-right">
            Недовольны доходом?
          </p>
          <p className="business-plank__text business-plank__text_type_middle">
            Онлайн-продажи
            <br />
            не радуют?
          </p>
          <p className="business-plank__text business-plank__text_type_bottom-left">
            Трудности с запуском
            <br />в интернете?
          </p>
          <p className="business-plank__text business-plank__text_type_bottom-right">
            Продажи ниже ожиданий?
          </p>
          <PlankArrowIcon
            mainClassName="business-plank__arrow"
            strokeClassName="business-plank__arrow-stroke"
          />
          <LightningIcon
            mainClassName="business-plank__icon"
            fillClassName="business-plank__icon-fill"
          />
        </div>
      </div>
    </PlankContainer>
  );
}

export default BusinessPlank;
