
import screen1 from '../../../assets/img/Portfolio/kover/Screen-1.webp';
import screen2 from '../../../assets/img/Portfolio/kover/Screen-2.webp';
import screen3 from '../../../assets/img/Portfolio/kover/Screen-3.webp';
import bg from '../../../assets/img/Portfolio/kover/bg.webp';
import logos from '../../../assets/img/Portfolio/kover/logos.webp';
import './Kover.css';

const Kover = () => {
    return (
        <>
            <img className='screen screen_kover' alt='' src={screen1}></img>
            <img className='screen screen_kover' alt='' src={screen2}></img>
            <img className='screen screen_kover' alt='' src={screen3}></img>
            <img className='screen screen_kover_logos' alt='' src={logos}></img>
            <img className='bg_kover' alt='' src={bg}></img>
        </>
    )
}
export default Kover