import './TarifBanner.css';
import banner from '../../../assets/images/tarif/banner.png'

function TarifBanner() {
    return (
        <div className='tarif-banner'>
            <div className='tarif-banner__content'>
                <img className='tarif-banner__img' src={banner} alt=''></img>
                <p className='tarif-banner__title'>Создано<br /> с пользой!</p>
                <p className='tarif-banner__text'>Мы&nbsp;предлагаем комплексное решение для создания интернет-магазина и&nbsp;управления <span className='tarif-banner__text_no-wrap'>им</span> с&nbsp;постоянной поддержкой на&nbsp;каждом этапе.</p>

                <svg
                    width={1340}
                    height={774}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className='tarif-banner__sq-1'
                    viewBox='0 0 1340 774'
                >
                    <path
                        opacity={0.5}
                        d="M670 0l670 387-670 387L0 387 670 0z"
                        fill="#332493"
                    />
                </svg>
                <svg
                    width={848}
                    height={490}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className='tarif-banner__sq-2'
                    viewBox='0 0 848 490'
                >
                    <path
                        opacity={0.5}
                        d="M424 0l424 245-424 245L0 245 424 0z"
                        fill="#080E3C"
                    />
                </svg>

                <svg
                    width={442}
                    height={198}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className='tarif-banner__sq-3'
                    viewBox='0 0 442 198'
                >
                    <path
                        d="M170.985 0l170.986 98.797-170.986 98.797L0 98.797 170.985 0z"
                        fill="#1A2267"
                    />
                    <path
                        d="M362.174 102.986L442 149.058l-79.826 46.073-79.826-46.073 79.826-46.072z"
                        fill="#262E77"
                    />
                </svg>
            </div>

        </div>
    );
}

export default TarifBanner