import './HowCards.css';

function DesignIcon({ mainClassName }) {
    return (
        <svg
            width={114}
            height={114}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox='0 0 114 114'
        >
            <path
                d="M80.893 72.295v-27.17M92.235 87.875l7.514-15.238-18.852-27.512-18.858 27.512 7.515 15.238M69.555 87.875h22.68v14.25h-22.68v-14.25z"
                stroke="#FF73F9"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M70.509 19.936C51.846 24.586 38 41.4 38 61.498"
                stroke="#fff"
                strokeWidth={6}
            />
            <path
                d="M72.438 19.95L28.5 19.903M21.375 27.046a7.128 7.128 0 01-7.125-7.125 7.128 7.128 0 017.125-7.125 7.125 7.125 0 110 14.25zM84.313 28.5h-7.126a4.752 4.752 0 01-4.75-4.75v-7.125a4.752 4.752 0 014.75-4.75h7.126a4.752 4.752 0 014.75 4.75v7.125a4.752 4.752 0 01-4.75 4.75zM41.563 78.123h-7.126a4.752 4.752 0 01-4.75-4.75v-7.125a4.752 4.752 0 014.75-4.75h7.126a4.752 4.752 0 014.75 4.75v7.125a4.749 4.749 0 01-4.75 4.75z"
                stroke="#fff"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function NotifyIcon({ mainClassName }) {
    return (
        <svg
            width={114}
            height={114}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox='0 0 114 114'
        >
            <path
                d="M74.746 31.711c-3.695-2.983-8.331-4.84-13.447-4.84h.01-8.612c-11.885 0-21.523 9.633-21.523 21.522V61.68a9.504 9.504 0 01-2.783 6.716l-3.045 3.045a9.503 9.503 0 00-2.784 6.717 8.976 8.976 0 008.978 8.977h50.925a8.976 8.976 0 008.977-8.977 9.503 9.503 0 00-2.783-6.717l-3.045-3.045a9.504 9.504 0 01-2.784-6.716v-9.434"
                stroke="#fff"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M46.113 87.134v1.734c0 6.008 4.874 10.882 10.887 10.882 6.014 0 10.887-4.874 10.887-10.887v-1.734M65.707 27.317v-4.36A8.706 8.706 0 0057 14.25a8.706 8.706 0 00-8.707 8.707v4.36"
                stroke="#fff"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M82.82 28.5c6.56 0 11.875 5.315 11.875 11.875S89.38 52.25 82.82 52.25s-11.875-5.315-11.875-11.875S76.265 28.5 82.82 28.5z"
                stroke="#FFA800"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}


function SuccessIcon({ mainClassName }) {
    return (
        <svg
            width={114}
            height={114}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox='0 0 114 114'
        >
            <path
                d="M37.593 21.782a9.5 9.5 0 010 13.436 9.5 9.5 0 01-13.436 0 9.5 9.5 0 010-13.436 9.5 9.5 0 0113.436 0z"
                stroke="#70E6FF"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M58.111 85.5H40.978c-6.892 0-12.478-6.38-12.478-14.245v-.014c0-7.867 5.586-14.246 12.478-14.246h32.039c6.892 0 12.478-6.379 12.478-14.245.005-7.88-5.59-14.26-12.488-14.25l-19.57.024"
                stroke="#fff"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M77.615 95.874l13.723-8.118c1.715-1.016 1.715-3.5 0-4.512l-13.723-8.118c-1.762-1.045-3.99.228-3.99 2.275V93.6c0 2.047 2.228 3.32 3.99 2.275z"
                stroke="#70E6FF"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function SeoIcon({ mainClassName }) {
    return (
        <svg
            width={114}
            height={114}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox='0 0 114 114'
        >
            <path
                d="M53.297 71.306l-28.386-.009c-9.56 0-17.311-7.81-17.311-17.444v-4.346c0-9.633 7.75-17.44 17.315-17.44h28.386l16.757-11.533c5.747-3.953 13.542.192 13.542 7.201v47.912c0 7.01-7.799 11.154-13.542 7.197L53.296 71.306zM53.303 32.076v39.23"
                stroke="#fff"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.584 71.306l6.617 16.816c1.182 3.008 4.047 4.978 7.227 4.978 5.501 0 9.266-5.64 7.237-10.84l-4.28-10.954"
                stroke="#fff"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M95 43.7v14.25M106.4 38v26.6"
                stroke="#00FF9B"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function IntegrationIcon({ mainClassName }) {
    return (
        <svg
            width={114}
            height={114}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox='0 0 114 114'
        >
            <path
                d="M51.703 26.125h26.672A11.875 11.875 0 0190.25 38v38M61.203 35.625l-9.5-9.5M61.203 16.625l-9.5 9.5M62.297 87.875H35.625A11.875 11.875 0 0123.75 76V38M52.797 78.375l9.5 9.5M52.797 97.375l9.5-9.5"
                stroke="#fff"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M90.25 99.75c6.558 0 11.875-5.317 11.875-11.875S96.808 76 90.25 76s-11.875 5.317-11.875 11.875S83.692 99.75 90.25 99.75zM23.75 38c6.558 0 11.875-5.317 11.875-11.875S30.308 14.25 23.75 14.25s-11.875 5.317-11.875 11.875S17.192 38 23.75 38z"
                stroke="#DE1BFF"
                strokeWidth={6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function HowCards({
    onStartPopupOpen,
}) {
    return (
        <div className='how-cards'>
            <div className='how-cards__content'>
                <div className='how-cards__cards'>
                    <div className='how-cards__card how-cards__card_1'>
                        <div className='how-cards__card-heading'>
                            <p className='how-cards__card-title'>Дизайн <br />и наполнение</p>
                            <DesignIcon
                                mainClassName={'how-cards__card-icon'}
                            />
                        </div>

                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>Адаптивный дизайн:</p>
                            <p className='how-cards__card-info-text'>Создаем удобный и привлекательный дизайн для всех устройств.</p>
                        </div>
                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>Полная поддержка:</p>
                            <p className='how-cards__card-info-text'>Если у вас нет базы товаров, мы поможем вам заполнить инвентарь.</p>
                        </div>
                        <button  onClick={onStartPopupOpen} className='how-cards__card-info__button'>
                            Дизайн и наполнение
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.9942 24C10.3532 24 8.8089 23.6863 7.36139 23.0591C5.91388 22.4395 4.63667 21.576 3.52975 20.4685C2.43058 19.3611 1.56749 18.0833 0.940493 16.635C0.313498 15.1868 0 13.6418 0 12C0 10.3582 0.313498 8.81317 0.940493 7.36496C1.56749 5.91675 2.43058 4.64279 3.52975 3.54308C4.63667 2.43562 5.91001 1.56825 7.34978 0.940949C8.79729 0.31365 10.3416 0 11.9826 0C13.6313 0 15.1795 0.31365 16.627 0.940949C18.0745 1.56825 19.3517 2.43562 20.4586 3.54308C21.5656 4.64279 22.4325 5.91675 23.0595 7.36496C23.6865 8.81317 24 10.3582 24 12C24 13.6418 23.6865 15.1868 23.0595 16.635C22.4325 18.0833 21.5656 19.3611 20.4586 20.4685C19.3517 21.576 18.0745 22.4395 16.627 23.0591C15.1795 23.6863 13.6352 24 11.9942 24ZM18.2409 11.9884C18.2409 11.6786 18.1016 11.3921 17.8229 11.1288L13.7475 7.09777C13.5539 6.90416 13.3062 6.80736 13.0044 6.80736C12.7257 6.80736 12.4935 6.90029 12.3077 7.08616C12.1219 7.27202 12.029 7.50823 12.029 7.79477C12.029 8.07357 12.1374 8.31752 12.3541 8.52662L13.7939 9.92062L15.1524 11.0126L12.6792 10.8964H6.80406C6.49444 10.8964 6.23899 11.001 6.03774 11.2101C5.84422 11.4114 5.74746 11.6709 5.74746 11.9884C5.74746 12.2982 5.84422 12.5576 6.03774 12.7667C6.23899 12.9681 6.49444 13.0687 6.80406 13.0687H12.6792L15.164 12.9526L13.8055 14.0561L12.3541 15.4385C12.1374 15.6321 12.029 15.88 12.029 16.182C12.029 16.4685 12.1219 16.7086 12.3077 16.9022C12.4935 17.0881 12.7257 17.181 13.0044 17.181C13.2985 17.181 13.5462 17.0803 13.7475 16.879L17.8229 12.848C18.1016 12.5847 18.2409 12.2982 18.2409 11.9884Z" fill="white" />
                            </svg>
                        </button>
                        <svg className='how-cards__card-sq-1' width="362" height="369" viewBox="0 0 362 369" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M421.5 0L843 243.5L421.5 487L0 243.5L421.5 0Z" fill="#6F1CC1" />
                        </svg>

                    </div>
                    <div className='how-cards__card how-cards__card_2'>
                        <div className='how-cards__card-heading'>
                            <p className='how-cards__card-title'>Управление <br />и уведомления</p>
                            <NotifyIcon
                                mainClassName={'how-cards__card-icon'}
                            />
                        </div>

                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>Личный кабинет для полного контроля:</p>
                            <p className='how-cards__card-info-text'>Управляйте заказами, статистикой, каталогом товаров и многим другим.</p>
                        </div>
                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>Делегирование управления:</p>
                            <p className='how-cards__card-info-text'>Наши специалисты могут взять на себя менеджмент и помочь вам в самых разных вопросах.</p>
                        </div>
                        <button onClick={onStartPopupOpen} className='how-cards__card-info__button'>
                            Управлять своим магазином
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.9942 24C10.3532 24 8.8089 23.6863 7.36139 23.0591C5.91388 22.4395 4.63667 21.576 3.52975 20.4685C2.43058 19.3611 1.56749 18.0833 0.940493 16.635C0.313498 15.1868 0 13.6418 0 12C0 10.3582 0.313498 8.81317 0.940493 7.36496C1.56749 5.91675 2.43058 4.64279 3.52975 3.54308C4.63667 2.43562 5.91001 1.56825 7.34978 0.940949C8.79729 0.31365 10.3416 0 11.9826 0C13.6313 0 15.1795 0.31365 16.627 0.940949C18.0745 1.56825 19.3517 2.43562 20.4586 3.54308C21.5656 4.64279 22.4325 5.91675 23.0595 7.36496C23.6865 8.81317 24 10.3582 24 12C24 13.6418 23.6865 15.1868 23.0595 16.635C22.4325 18.0833 21.5656 19.3611 20.4586 20.4685C19.3517 21.576 18.0745 22.4395 16.627 23.0591C15.1795 23.6863 13.6352 24 11.9942 24ZM18.2409 11.9884C18.2409 11.6786 18.1016 11.3921 17.8229 11.1288L13.7475 7.09777C13.5539 6.90416 13.3062 6.80736 13.0044 6.80736C12.7257 6.80736 12.4935 6.90029 12.3077 7.08616C12.1219 7.27202 12.029 7.50823 12.029 7.79477C12.029 8.07357 12.1374 8.31752 12.3541 8.52662L13.7939 9.92062L15.1524 11.0126L12.6792 10.8964H6.80406C6.49444 10.8964 6.23899 11.001 6.03774 11.2101C5.84422 11.4114 5.74746 11.6709 5.74746 11.9884C5.74746 12.2982 5.84422 12.5576 6.03774 12.7667C6.23899 12.9681 6.49444 13.0687 6.80406 13.0687H12.6792L15.164 12.9526L13.8055 14.0561L12.3541 15.4385C12.1374 15.6321 12.029 15.88 12.029 16.182C12.029 16.4685 12.1219 16.7086 12.3077 16.9022C12.4935 17.0881 12.7257 17.181 13.0044 17.181C13.2985 17.181 13.5462 17.0803 13.7475 16.879L17.8229 12.848C18.1016 12.5847 18.2409 12.2982 18.2409 11.9884Z" fill="white" />
                            </svg>
                        </button>
                        <svg className='how-cards__card-sq-2' width="560" height="244" viewBox="0 0 560 244" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M421.5 -243L843 0.5L421.5 244L0 0.5L421.5 -243Z" fill="#D73434" />
                        </svg>

                    </div>
                    <div className='how-cards__card how-cards__card_3'>
                        <div className='how-cards__card-heading'>
                            <p className='how-cards__card-title'>Ваш путь <br />к успеху</p>
                            <SuccessIcon
                                mainClassName={'how-cards__card-icon'}
                            />
                        </div>

                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>Единый тариф:</p>
                            <p className='how-cards__card-info-text'>Наш уникальный тарифный план в размере 18 000 рублей в месяц обеспечивает полный спектр услуг для создания и управления вашим интернет-магазином.</p>
                        </div>
                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>Постоянная поддержка:</p>
                            <p className='how-cards__card-info-text'>Мы находимся рядом на каждом этапе вашего пути, предлагая помощь и профессиональные советы.</p>
                        </div>
                        <button className='how-cards__card-price' onClick={onStartPopupOpen} type='button'>18 000 ₽ / месяц</button>

                        <svg className='how-cards__card-sq-3' width="473" height="348" viewBox="0 0 473 348" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-128.5 -347L473 0.5L-128.5 348L-730 0.5L-128.5 -347Z" fill="#14A9C8" />
                        </svg>

                    </div>
                    <div className='how-cards__card how-cards__card_4'>
                        <div className='how-cards__card-heading'>
                            <p className='how-cards__card-title'>Рост <br />и продвижение</p>
                            <SeoIcon
                                mainClassName={'how-cards__card-icon'}
                            />
                        </div>

                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>SEO и маркетинг:</p>
                            <p className='how-cards__card-info-text'>Используйте наши продвинутые инструменты для увеличения видимости и продаж.</p>
                        </div>
                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>Индивидуальный домен:</p>
                            <p className='how-cards__card-info-text'>Создайте уникальный и запоминающийся адрес для вашего магазина.</p>
                        </div>
                        <button  onClick={onStartPopupOpen} className='how-cards__card-info__button'>
                            Начать продвижение бизнеса
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.9942 24C10.3532 24 8.8089 23.6863 7.36139 23.0591C5.91388 22.4395 4.63667 21.576 3.52975 20.4685C2.43058 19.3611 1.56749 18.0833 0.940493 16.635C0.313498 15.1868 0 13.6418 0 12C0 10.3582 0.313498 8.81317 0.940493 7.36496C1.56749 5.91675 2.43058 4.64279 3.52975 3.54308C4.63667 2.43562 5.91001 1.56825 7.34978 0.940949C8.79729 0.31365 10.3416 0 11.9826 0C13.6313 0 15.1795 0.31365 16.627 0.940949C18.0745 1.56825 19.3517 2.43562 20.4586 3.54308C21.5656 4.64279 22.4325 5.91675 23.0595 7.36496C23.6865 8.81317 24 10.3582 24 12C24 13.6418 23.6865 15.1868 23.0595 16.635C22.4325 18.0833 21.5656 19.3611 20.4586 20.4685C19.3517 21.576 18.0745 22.4395 16.627 23.0591C15.1795 23.6863 13.6352 24 11.9942 24ZM18.2409 11.9884C18.2409 11.6786 18.1016 11.3921 17.8229 11.1288L13.7475 7.09777C13.5539 6.90416 13.3062 6.80736 13.0044 6.80736C12.7257 6.80736 12.4935 6.90029 12.3077 7.08616C12.1219 7.27202 12.029 7.50823 12.029 7.79477C12.029 8.07357 12.1374 8.31752 12.3541 8.52662L13.7939 9.92062L15.1524 11.0126L12.6792 10.8964H6.80406C6.49444 10.8964 6.23899 11.001 6.03774 11.2101C5.84422 11.4114 5.74746 11.6709 5.74746 11.9884C5.74746 12.2982 5.84422 12.5576 6.03774 12.7667C6.23899 12.9681 6.49444 13.0687 6.80406 13.0687H12.6792L15.164 12.9526L13.8055 14.0561L12.3541 15.4385C12.1374 15.6321 12.029 15.88 12.029 16.182C12.029 16.4685 12.1219 16.7086 12.3077 16.9022C12.4935 17.0881 12.7257 17.181 13.0044 17.181C13.2985 17.181 13.5462 17.0803 13.7475 16.879L17.8229 12.848C18.1016 12.5847 18.2409 12.2982 18.2409 11.9884Z" fill="white" />
                            </svg>
                        </button>
                        <svg className='how-cards__card-sq-4' width="378" height="454" viewBox="0 0 378 454" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M416.5 -27L833 213.5L416.5 454L0 213.5L416.5 -27Z" fill="#00945A" />
                        </svg>

                    </div>
                 
                    <div className='how-cards__card how-cards__card_5'>
                        <div className='how-cards__card-heading'>
                            <p className='how-cards__card-title'>Полная <br /> интеграция<br />и гибкие решения</p>
                            <IntegrationIcon
                                mainClassName={'how-cards__card-icon'}
                            />
                        </div>

                        <div className='how-cards__card-info'>
                            <p className='how-cards__card-info-title'>Широкий выбор интеграций:</p>
                            <p className='how-cards__card-info-text'>Подключите сервисы, необходимые для вашего бизнеса.</p>
                        </div>
                        <button  onClick={onStartPopupOpen} className='how-cards__card-info__button'>
                            Изучить возможности платформы
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11.9942 24C10.3532 24 8.8089 23.6863 7.36139 23.0591C5.91388 22.4395 4.63667 21.576 3.52975 20.4685C2.43058 19.3611 1.56749 18.0833 0.940493 16.635C0.313498 15.1868 0 13.6418 0 12C0 10.3582 0.313498 8.81317 0.940493 7.36496C1.56749 5.91675 2.43058 4.64279 3.52975 3.54308C4.63667 2.43562 5.91001 1.56825 7.34978 0.940949C8.79729 0.31365 10.3416 0 11.9826 0C13.6313 0 15.1795 0.31365 16.627 0.940949C18.0745 1.56825 19.3517 2.43562 20.4586 3.54308C21.5656 4.64279 22.4325 5.91675 23.0595 7.36496C23.6865 8.81317 24 10.3582 24 12C24 13.6418 23.6865 15.1868 23.0595 16.635C22.4325 18.0833 21.5656 19.3611 20.4586 20.4685C19.3517 21.576 18.0745 22.4395 16.627 23.0591C15.1795 23.6863 13.6352 24 11.9942 24ZM18.2409 11.9884C18.2409 11.6786 18.1016 11.3921 17.8229 11.1288L13.7475 7.09777C13.5539 6.90416 13.3062 6.80736 13.0044 6.80736C12.7257 6.80736 12.4935 6.90029 12.3077 7.08616C12.1219 7.27202 12.029 7.50823 12.029 7.79477C12.029 8.07357 12.1374 8.31752 12.3541 8.52662L13.7939 9.92062L15.1524 11.0126L12.6792 10.8964H6.80406C6.49444 10.8964 6.23899 11.001 6.03774 11.2101C5.84422 11.4114 5.74746 11.6709 5.74746 11.9884C5.74746 12.2982 5.84422 12.5576 6.03774 12.7667C6.23899 12.9681 6.49444 13.0687 6.80406 13.0687H12.6792L15.164 12.9526L13.8055 14.0561L12.3541 15.4385C12.1374 15.6321 12.029 15.88 12.029 16.182C12.029 16.4685 12.1219 16.7086 12.3077 16.9022C12.4935 17.0881 12.7257 17.181 13.0044 17.181C13.2985 17.181 13.5462 17.0803 13.7475 16.879L17.8229 12.848C18.1016 12.5847 18.2409 12.2982 18.2409 11.9884Z" fill="white" />
                            </svg>
                        </button>
                        <svg className='how-cards__card-sq-5' width="590" height="212" viewBox="0 0 590 212" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M295.5 0L712 240.5L295.5 481L-121 240.5L295.5 0Z" fill="#6C137B" />
                        </svg>

                    </div>
               
                <div className='how-cards__subinfo'>
                    <div className='how-cards__logo'>
                        <div className='how-cards__logo-sq how-cards__logo-sq_1'></div>
                        <div className='how-cards__logo-sq how-cards__logo-sq_2'></div>
                        <div className='how-cards__logo-sq how-cards__logo-sq_3'></div>
                    </div>
                    <p className='how-cards__subinfo-title'>Ваш идеальный <br />
                        интернет-магазин <br />
                        ждет вас!</p>
                    <p className='how-cards__subinfo-text'>Присоединяйтесь к Polza сегодня и откройте новые горизонты для вашего бизнеса. </p>

                </div>
                <svg className='how-cards__sq-1' width="1125" height="651" viewBox="0 0 1125 651" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M562.5 0L1125 325.5L562.5 651L0 325.5L562.5 0Z" fill="#221E76" />
                </svg>
                </div>
            </div>
        </div>
    );
}

export default HowCards