import { useEffect, useState } from "react";
import { RhombShape } from "../../../assets/icon/marketplaceIcons";
import PopupInput from "../../PopupInput/PopupInput";
import Checkbox from "../../Checkbox/Checkbox";
import user_agreement_file from "../../../assets/legal-docs/user_agreement_polza.tech.pdf";


import "./MarketForm.css";
import mainApi from "../../../assets/api/MainApi";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import { sendMetriс } from "../../../assets/utils/utils";

const ID = 'market-form'
function MarketForm() {
  const [values, setValues] = useState({
    name: "",
    phone: "",
    agreement: false,
  });
  const [formValid, setFormValid] = useState({
    name: {},
    phone: {},
    agreement: {},
  });
  const isDisabled =
    !formValid.name?.validState ||
    !formValid.phone?.validState ||
    !formValid.agreement?.validState;

  function handleChange(e) {
    const input = e.target;
    const name = input.name;
    const type = input.type;
    const value = type === "checkbox" ? input.checked : input.value;

    switch (name) {
      case "phone":
        let inputValue = value.replace(/\D/g, "");
        let formattedInputValue = "";
        if (!inputValue) {
          setValues(prevValue => ({
            ...prevValue,
            [name]: "",
          }));
          setFormValid((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: "Можно вводить только цифры",
              validState: false,
            },
          }));
        } else {
          if (["7", "8", "9"].indexOf(inputValue[0]) > -1) {
            setFormValid((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: "",
                validState: false,
              },
            }));
            if (inputValue[0] === "9") inputValue = "7" + inputValue;

            let firstSimbols = inputValue[0] === "8" ? "8" : "+7";
            formattedInputValue = firstSimbols + " ";

            if (inputValue.length > 1) {
              formattedInputValue += "(" + inputValue.substring(1, 4);
            }
            if (inputValue.length >= 5) {
              formattedInputValue += ") " + inputValue.substring(4, 7);
            }
            if (inputValue.length >= 8) {
              formattedInputValue += "-" + inputValue.substring(7, 9);
            }
            if (inputValue.length >= 10) {
              formattedInputValue += "-" + inputValue.substring(9, 11);
            }
            if (inputValue.length >= 11) {
              setFormValid((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: "",
                  validState: true,
                },
              }));
            } else {
              setFormValid((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: "",
                  validState: false,
                },
              }));
            }
          } else {
            formattedInputValue = "+" + inputValue.substring(0, 16);
            if (inputValue.length >= 11) {
              setFormValid((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: "",
                  validState: true,
                },
              }));
            } else {
              setFormValid((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: "",
                  validState: false,
                },
              }));
            }
          }

          setValues((prevValue) => ({
            ...prevValue,
            [name]: formattedInputValue,
          }));
        }
        break;

      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        setFormValid((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: "",
            validState: Boolean(value),
          },
        }));
        break;
    }
  }

  function handlePhoneDelete(e) {
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, "").length === 1) {
      setValues((prevValue) => ({
        ...prevValue,
        phone: "",
      }));
    }
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, "").length < 11) {
      setFormValid((prevValue) => ({
        ...prevValue,
        phone: {
          errorMessage: "",
          validState: false,
        },
      }));
    }
  }

  function handleResetForm() {
    setValues({
      name: "",
      phone: "",
      agreement: false,
    })
    setFormValid({
      name: {},
      phone: {},
      agreement: {},
    })
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    submitMarketplace()
  };
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false);
  const [step, setStep] = useState(0)
  function submitMarketplace() {
    setIsPreloaderVisible(true);
    mainApi
      .submitMarketplace({ phone: values.phone.replace(/\D/g, ""), name: values.name })
      .then((res) => {
        console.log(res.data);
        sendMetriс('reachGoal', 'LEAD_FORM_MARKETPLACE')
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handleResetForm()
        setStep(1)
        setTimeout(() => {
          setStep(0)
        }, 2000);
        setIsPreloaderVisible(false);
      });
  }


  useEffect(() => { // Add this useEffect hook
    // Check if the URL contains '#main-intro'
    if (window.location.href.includes(`#${ID}`)) {
      const element = document.getElementById(ID);
      if (element) {
        // Scroll the element into view
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, []);



  return (
    <div className="market-form" id={ID}>
      <div className="market-form__container">
        <div className="market-form__title-block">
          <p className="market-form__title">Индивидуальная консультация</p>
          <p className="market-form__text">
            Получите бесплатный полноценный разбор вашего бизнеса и ответы на
            все интересующие вас вопросы.
          </p>
        </div>

        <form className="market-form__form" onSubmit={handleSubmit}>
          <div className="market-form__inputs-block">
            <PopupInput
              label={"Ваше имя"}
              value={values.name}
              onChange={handleChange}
              name={"name"}
              error={formValid.name}
            />
            <PopupInput
              label={"Номер телефона"}
              value={values.phone}
              onChange={handleChange}
              name={"phone"}
              inputMode="tel"
              error={formValid.phone}
              onKeyDown={handlePhoneDelete}
            />
          </div>
          <div className="market-form__btn-block">
            <div className="market-form__checkbox">
              <Checkbox
                label={`<p class="market-form__checkbox-accept">Соглашаюсь с${" "}<a
                  class="market-form__checkbox-accept-link"
                  href="${user_agreement_file}"
                  target="_blank"
                  rel="noreferrer"
                > политикой обработки персональных данных</a>${" "} сервиса</p>`}
                checked={values.agreement}
                onChange={handleChange}
                name={"agreement"}
                error={formValid.agreement?.errorMessage}
              />
            </div>
            <button
              className={`market-form__submit-btn ${isDisabled ? "market-form__submit-btn_disabled" : ""
                }`}
              type="submit"
            >
              {isPreloaderVisible ?
                <MiniPreloader />
                :
                step === 0 ? 'Отправить' : 'Отправлено'
              }

            </button>
          </div>
        </form>

        <RhombShape
          mainClassName="market-form__rhomb market-form__rhomb_type_top-left"
          fillClassName="market-form__rhomb-fill"
        />
        <RhombShape
          mainClassName="market-form__rhomb market-form__rhomb_type_bottom-left"
          fillClassName="market-form__rhomb-fill"
        />
        <RhombShape
          mainClassName="market-form__rhomb market-form__rhomb_type_bottom-right"
          fillClassName="market-form__rhomb-fill"
        />
      </div>
    </div>
  );
}

export default MarketForm;
