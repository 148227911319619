import { useEffect, useRef, useState } from "react";
import "./SmallFormPopup.css";
import PopupInput from "../PopupInput/PopupInput";
import { PopupCloseIcon, ProjectLogoIcon } from "../../assets/icon/mainIcon";
import {
  LK_LINK,
  LK_LINK_AUTH,
  LK_LINK_SIGNUP,
} from "../../assets/utils/constants";
import mainApi from "../../assets/api/MainApi";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import user_agreement_file from "../../assets/legal-docs/user_agreement_polza.tech.pdf";
import { useLocation } from "react-router-dom";
import { sendMetriс } from "../../assets/utils/utils";

function SmallFormPopup({ isOpen, onClose }) {
  const location = useLocation();
  const is_marketplace_location = location.pathname === "/marketplaces"
  const [step, setStep] = useState(0)
  const [form, setForm] = useState({ phone: "", name: "" });
  const [formValid, setFormValid] = useState({ phone: {} });
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(false);

  useEffect(() => {
    if (!isOpen) return
    sendMetriс('reachGoal', 'OPEN_FORMS')

  }, [isOpen])
  
  function handleClose() {
    onClose();
    setTimeout(() => {
      setForm({ phone: "", name: "" });
      setFormValid({ phone: {} });
      setStep(0)
    }, 300);

  }

  function handleChange(e) {
    const input = e.target;
    const name = input.name;
    const value = input.value;

    switch (name) {
      case "phone":
        let inputValue = value.replace(/\D/g, "");
        let formattedInputValue = "";
        if (!inputValue) {
          setForm(prevValue => ({
            ...prevValue,
            phone: ""
          }));
          setFormValid((prevValue) => ({
            ...prevValue,
            phone: {
              errorMessage: "Можно вводить только цифры",
              validState: false,
            },
          }));
        } else {
          if (["7", "8", "9"].indexOf(inputValue[0]) > -1) {
            setFormValid((prevValue) => ({
              ...prevValue,
              phone: {
                errorMessage: "",
                validState: false,
              },
            }));
            if (inputValue[0] === "9") inputValue = "7" + inputValue;

            let firstSimbols = inputValue[0] === "8" ? "8" : "+7";
            formattedInputValue = firstSimbols + " ";

            if (inputValue.length > 1) {
              formattedInputValue += "(" + inputValue.substring(1, 4);
            }
            if (inputValue.length >= 5) {
              formattedInputValue += ") " + inputValue.substring(4, 7);
            }
            if (inputValue.length >= 8) {
              formattedInputValue += "-" + inputValue.substring(7, 9);
            }
            if (inputValue.length >= 10) {
              formattedInputValue += "-" + inputValue.substring(9, 11);
            }
            if (inputValue.length >= 11) {
              setFormValid((prevValue) => ({
                ...prevValue,
                phone: {
                  errorMessage: "",
                  validState: true,
                },
              }));
            } else {
              setFormValid((prevValue) => ({
                ...prevValue,
                phone: {
                  errorMessage: "",
                  validState: false,
                },
              }));
            }
          } else {
            formattedInputValue = "+" + inputValue.substring(0, 16);
            if (inputValue.length >= 11) {
              setFormValid((prevValue) => ({
                ...prevValue,
                phone: {
                  errorMessage: "",
                  validState: true,
                },
              }));
            } else {
              setFormValid((prevValue) => ({
                ...prevValue,
                phone: {
                  errorMessage: "",
                  validState: false,
                },
              }));
            }
          }

          setForm((prevValue) => ({
            ...prevValue,
            phone: formattedInputValue,
          }));
        }
        break;

      default:
        setForm((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;
    }
  }

  function handlePhoneDelete(e) {
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, "").length === 1) {
      setForm((prevValue) => ({
        ...prevValue,
        phone: "",
      }));
    }
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, "").length < 11) {
      setFormValid((prevValue) => ({
        ...prevValue,
        phone: {
          errorMessage: "",
          validState: false,
        },
      }));
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    is_marketplace_location ? submitMarketplace() : sendPhone();
  };

  function sendPhone() {
    setIsPreloaderVisible(true);
    mainApi
      .sendPhone({ phone: form.phone.replace(/\D/g, "") })
      .then((res) => {
        console.log(res.data);
        sendMetriс('reachGoal', 'LEAD_FORM_IM')
        window.location.href = `${LK_LINK}/${LK_LINK_AUTH}/${LK_LINK_SIGNUP}?phone=${form.phone.replace(
          /\D/g,
          ""
        )}`;

      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPreloaderVisible(false);
      });
  }

  function submitMarketplace() {
    setIsPreloaderVisible(true);
    mainApi
      .submitMarketplace({ phone: form.phone.replace(/\D/g, ""), name: form.name })
      .then((res) => {
        console.log(res.data);
        sendMetriс('reachGoal', 'LEAD_FORM_MARKETPLACE')
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setStep(1)
        setIsPreloaderVisible(false);
      });
  }

  const inputRef = useRef(null);
  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      if (!inputRef.current) return
      inputRef.current.focus();
    }, 100);
  }, [isOpen]);

  return (
    <div
      className={`small-form-popup ${isOpen ? "small-form-popup_opened" : ""}`}
      onClick={handleClose}
    >
      <div
        className={`small-form-popup__container ${step === 1 ? 'small-form-popup__container_no-bg' : ''}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="small-form-popup__close-btn"
          type="button"
          ariaLabel="Закрыть попап"
          onClick={handleClose}
        >
          <PopupCloseIcon
            mainClassName="small-form-popup__close-icon"
            strokeClassName="small-form-popup__close-icon-stroke"
          />
        </button>
        <ProjectLogoIcon
          mainClassName="small-form-popup__logo"
          fillClassName="small-form-popup__logo-fill"
        />
        {
          step === 0 ?
            <>
              <form className="small-form-popup__form" onSubmit={handleSubmit}>
                {is_marketplace_location ? (
                  <PopupInput
                    label={"Ваше имя"}
                    value={form.name}
                    onChange={handleChange}
                    name={"name"}
                    error={formValid.name}
                    refObj={is_marketplace_location ? inputRef : null}
                  />
                ) : null}
                <PopupInput
                  label={"Номер телефона"}
                  value={form.phone}
                  onChange={handleChange}
                  name={"phone"}
                  inputMode="tel"
                  error={formValid.phone}
                  onKeyDown={handlePhoneDelete}
                  refObj={is_marketplace_location ? null : inputRef}
                />
                <button
                  className={`small-form-popup__sbm-btn ${!(formValid.phone.validState && form.phone && (is_marketplace_location ? form.name.length > 0 : true))
                    ? "small-form-popup__sbm-btn_disabled"
                    : ""
                    }`}
                  type="submit"
                >
                  {isPreloaderVisible ? <MiniPreloader /> : "Отправить"}
                </button>
              </form>
              <p className="register__accept">
                Соглашаюсь с{" "}
                <a
                  className="register__accept-link"
                  href={user_agreement_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  политикой обработки персональных данных
                </a>{" "}
                сервиса
              </p>
            </>
            :
            null
        }
        {
          step === 1 ?
            <div className="small-form-popup__success">
              <p className="small-form-popup__success-title">Польза в пути!</p>
              <p className="small-form-popup__success-text">В ближайшее время с вами свяжется наш менеджер для уточнения деталей. Благодарим за обращение!</p>
            </div>
            :
            null
        }

      </div>
    </div>
  );
}

export default SmallFormPopup;
